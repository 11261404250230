import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

export function usePaymentDetail({ orderNumber }) {
  const { data: payment } = useFetch([], async () => {
    const { data } = await apiClient({
      url: `/payments/${orderNumber}`,
      method: "get",
    });

    return data;
  });

  return { payment };
}
