import { useState } from "react";
import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useSettlements({ page, searchState }) {
  const [checkSettlements, setCheckSettlements] = useState();
  const [totalElements, setTotalElements] = useState();
  const [totalPages, setTotalPages] = useState();
  const { storeName } = searchState;

  const params = Object.assign(
    { page, size: POST_SIZE },
    storeName !== "" && { storeName }
  );

  const { isLoading, refetch } = useFetch(
    [page, storeName],
    async () => {
      const { data } = await apiClient({
        url: "/settlements",
        method: "get",
        params,
      });

      return data;
    },
    {
      onSuccess: (data) => {
        const mapedContent = data.content.map((content) => ({
          ...content,
          isChecked: false,
        }));

        setTotalElements(data.totalElements);
        setTotalPages(data.totalPages);
        setCheckSettlements([...mapedContent]);
      },
    }
  );

  return {
    settlements: checkSettlements,
    isSettlementsLoading: isLoading,
    checkSettlements,
    setCheckSettlements,
    refetch,
    totalElements,
    totalPages,
  };
}
