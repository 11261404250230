import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import EasyPaymentModal from "../common/EasyPaymentModal";
import { useEasyPaymentModal } from "../../hooks/dashboard/useEasyPaymentModal";
import "./HorizonBarChart.css";

export default function HorizonBarChart({
  labels,
  datas,
  paymentCount,
  paymentInfo,
}) {
  // const { isShowingModal, handleShowModal, handleHideModal } =
  //   useEasyPaymentModal();

  const options = {
    indexAxis: "y",
    barThickness: 3,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      layout: {
        padding: {
          right: 150,
          left: 150,
        },
      },
      datalabels: {
        anchor: "end",
        align: "right",
        font: function (context) {
          const { dataIndex } = context;

          if (paymentInfo[dataIndex].payMethod !== "간편결제") {
            return { size: 14, weight: 500 };
          }

          return { size: 14, weight: 700 };
        },
        // listeners: {
        //   click: (evt) => {
        //     if (paymentInfo[evt.dataIndex].payMethod !== "간편결제") {
        //       return;
        //     }

        //     handleShowModal();
        //   },
        //   enter: (evt) => {
        //     if (paymentInfo[evt.dataIndex].payMethod !== "간편결제") {
        //       return;
        //     }

        //     document.getElementById("root").style.cursor = "pointer";
        //   },
        //   leave: (evt) => {
        //     if (paymentInfo[evt.dataIndex].payMethod !== "간편결제") {
        //       return;
        //     }

        //     document.getElementById("root").style.cursor = "default";
        //   },
        // },
        formatter: function (value, context) {
          const { dataIndex } = context;
          const payType = paymentInfo[dataIndex];

          return `${payType.payMethod} (${payType.count}건/${Math.round(
            (payType.count / paymentCount) * 100
          )}%)`;
        },
      },
    },
    layout: {
      padding: {
        right: 150,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: datas,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      <Bar
        width={500}
        height={200}
        options={options}
        data={data}
        plugins={[ChartDataLabels]}
      />
      {/* <EasyPaymentModal
        isShowingModal={isShowingModal}
        onHide={handleHideModal}
      /> */}
    </>
  );
}
