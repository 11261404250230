import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import SimplePagination from "../../../components/common/SimplePagination";
import useAdvertisements from "../../../hooks/advertisement/useAdvertisements";
import CustomSpinner from "../../../components/common/CustomSpinner";
import AdvertisementBoard from "../../../components/advertisement/AdvertisementBoard";

const Advertisement = ({ adRequestRef }) => {
  const [page, setPage] = useState(0);

  const { advertisements } = useAdvertisements({ page, adRequestRef });

  if (!advertisements) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">광고 내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{advertisements.totalElements}</h5>
              </Col>
            </Row>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>광고 ID</th>
                    <th>광고 명</th>
                    <th>광고 이미지</th>
                    <th>순서</th>
                    <th>게시 시작일</th>
                    <th>게시 종료일</th>
                    <th>링크</th>
                    <th>생성일자</th>
                  </tr>
                </thead>
                <tbody>
                  <AdvertisementBoard advertisements={advertisements} />
                </tbody>
              </Table>
              <SimplePagination
                currentPage={page}
                totalPage={advertisements.totalPages}
                setPage={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Advertisement;
