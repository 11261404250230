import { useNavigate } from "react-router-dom";
import { useLogoDownload } from "../../hooks/common/useLogoDownload";
import CustomSpinner from "../common/CustomSpinner";

export default function StoreBoard({ stores }) {
  const navigate = useNavigate();
  const { mutateLogo, isLoading } = useLogoDownload();

  if (isLoading) {
    return <CustomSpinner type="backdrop" />;
  }

  const handleLogoDownloadBtnClick = (domain, logoUrl) => {
    if (isLoading) {
      return;
    }

    mutateLogo({ domain, logoUrl });
  };

  const mapedStores = stores.content.map((store) => {
    const {
      storeId,
      storeName,
      businessHours,
      domain,
      location,
      logoUrl,
      status,
      createdAt,
    } = store;

    return (
      <tr
        key={storeId}
        onClick={() => navigate(`/admin/store/stores/${storeId}`)}
        className="board-row"
      >
        <td>{storeId || "-"}</td>
        <td>{storeName || "-"}</td>
        <td>{businessHours || "-"}</td>
        <td>{location || "-"}</td>
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleLogoDownloadBtnClick(domain, logoUrl);
            }}
          >
            로고 다운로드
          </button>
        </td>
        <td>{status ? status.description : "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
      </tr>
    );
  });

  return mapedStores;
}
