import { useLocation } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useStores({ page, storeRequestRef }) {
  const location = useLocation();

  const { data: stores } = useFetch([page], async () => {
    if (storeRequestRef.current && page === 0 && !!location.search) {
      return;
    }

    const { data } = await apiClient({
      url: "/stores",
      method: "get",
      params: {
        page,
        size: POST_SIZE,
      },
    });

    return data;
  });

  return { stores };
}
