import { useParams } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useCouponDetailLogs({ page }) {
  const { couponId } = useParams();

  const { data: couponLogs } = useFetch([], async () => {
    const { data } = await apiClient({
      url: `/coupons/${couponId}/coupon-logs`,
      params: {
        page,
        size: POST_SIZE,
        couponId,
      },
    });

    return data;
  });

  return { couponLogs };
}
