const getTwoLetters = (value) => {
  return ("00" + value).slice(-2);
};

export const getTodayTime = (hour) => {
  if (!hour) {
    return new Date().getHours();
  }

  return new Date().setHours(hour, 0, 0, 0);
};

export const getHourTypeString = (time) => {
  return String(new Date(time).getHours());
};

export const getMonthTypeString = (month) => {
  return String(new Date(month).getMonth());
};

export const getTodayTimeTypeString = (date) => {
  const newDate = new Date(date);

  return `${newDate.getFullYear()}-${getTwoLetters(
    newDate.getMonth() + 1
  )}-${getTwoLetters(newDate.getDate())} ${getTwoLetters(
    newDate.getHours()
  )}:00`;
};

export const getDateTimeTypeString = (date) => {
  const newDate = new Date(date);

  return `${newDate.getFullYear()}-${getTwoLetters(
    newDate.getMonth() + 1
  )}-${getTwoLetters(newDate.getDate())} 00:00`;
};

export const getMonthTimeTypeString = (date) => {
  const newDate = new Date(date);

  return `${newDate.getFullYear()}-${getTwoLetters(
    newDate.getMonth() + 1
  )}-01 00:00`;
};

export const transformYYYYMMDDHHMM = (date) => {
  const newDate = date ? new Date(date) : new Date();

  const mmMonth = getTwoLetters(newDate.getMonth() + 1);
  const ddDate = getTwoLetters(newDate.getDate());
  const hhHour = getTwoLetters(newDate.getHours());
  const mmMinute = getTwoLetters(newDate.getMinutes());

  return `${newDate.getFullYear()}-${mmMonth}-${ddDate} ${hhHour}:${mmMinute}`;
};

export const getTodayHourTypeDate = () => {
  return new Date().setHours(new Date().getHours(), 0, 0, 0);
};

export const getTodayHourTypeDateByValue = (value) => {
  return new Date().setHours(new Date(parseInt(value)).getHours(), 0, 0, 0);
};

export const getParamsDateAndTime = (date, hour) => {
  const newYear = date.getFullYear();
  const newMonth = ("00" + (date.getMonth() + 1)).slice(-2);
  const newDate = ("00" + date.getDate()).slice(-2);

  return `${newYear}-${newMonth}-${newDate} ${("00" + hour).slice(-2)}:00`;
};

export const getParamsDate = (date) => {
  const newYear = date.getFullYear();
  const newMonth = ("00" + (date.getMonth() + 1)).slice(-2);
  const newDate = ("00" + date.getDate()).slice(-2);

  return `${newYear}-${newMonth}-${newDate}`;
};

export function transformYYYYMMDD(date) {
  const newDate = date ? new Date(date) : new Date();
  const mmMonth = ("00" + (newDate.getMonth() + 1)).slice(-2);
  const ddDate = ("00" + newDate.getDate()).slice(-2);

  return `${newDate.getFullYear()}-${mmMonth}-${ddDate}`;
}
