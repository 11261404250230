import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function VerticalBarChart({
  labels,
  firstData,
  firstLabel,
  secondData,
  secondLabel,
}) {
  const options = {
    elements: {
      bar: {
        borderWidth: 3,
        borderColor: "#e9ecef",
      },
    },
    barThickness: 20,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        font: { size: 14 },
        formatter: function (value, context) {
          if (value === 0) {
            return "";
          }

          return value;
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        right: 150,
        left: 150,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: firstLabel,
        data: firstData,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderRadius: 10,
      },
      {
        label: secondLabel,
        data: secondData,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderRadius: 10,
      },
    ],
  };

  return (
    <Bar
      width={500}
      height={400}
      options={options}
      data={data}
      plugins={[ChartDataLabels]}
    />
  );
}
