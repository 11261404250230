export const initialState = {
  storeName: "",
  ownerName: "",
  buyerName: "",
  status: "",
  startTimeOfCreatedAt: new Date(),
  endTimeOfCreatedAt: new Date(),
  createdStartTime: "0",
  createdEndTime: "23",
  startTimeOfUsedAt: new Date(),
  endTimeOfUsedAt: new Date(),
  usedStartTime: "0",
  usedEndTime: "23",
};

export function couponReducer(state, action) {
  switch (action.type) {
    case "changed_storeName":
      return {
        ...state,
        storeName: action.payload,
      };
    case "changed_ownerName":
      return {
        ...state,
        ownerName: action.payload,
      };
    case "changed_buyerName":
      return {
        ...state,
        buyerName: action.payload,
      };
    case "changed_status":
      return {
        ...state,
        status: action.payload,
      };
    case "changed_createDate":
      if (action.start) {
        return { ...state, startTimeOfCreatedAt: action.start };
      }

      return {
        ...state,
        endTimeOfCreatedAt: action.end,
      };
    case "changed_createTime":
      if (action.start) {
        return { ...state, createdStartTime: action.start };
      }

      return {
        ...state,
        createdEndTime: action.end,
      };
    case "changed_useDate":
      if (action.start) {
        return { ...state, startTimeOfUsedAt: action.start };
      }

      return {
        ...state,
        endTimeOfUsedAt: action.end,
      };
    case "changed_useTime":
      if (action.start) {
        return { ...state, usedStartTime: action.start };
      }

      return {
        ...state,
        usedEndTime: action.end,
      };
    case "reseted":
      return { ...initialState };
    case "moved":
      return { ...action.payload };
    default:
      return Error(`Unknown action type: ${action.type}`);
  }
}
