
import { Accordion } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import {useEffect} from "react";
import {Image} from "react-bootstrap";


interface LeftMenuItemProps {
    imgsrc:'',name:'',path:'',move:boolean,children:[{name:'1',path:''}]
}

export default function LeftMenuItem( props :LeftMenuItemProps){

    return(
        <>
            <Accordion.Item eventKey={props.path} >
                {props.move ? <Link as={NavLink} to={props.path}><Accordion.Header><Image src={props.imgsrc} className="img-fluid"></Image>{props.name}</Accordion.Header></Link> :<Accordion.Header><Image src={props.imgsrc} className="img-fluid"></Image>{props.name}</Accordion.Header>}
                { props.children &&
                    <Accordion.Body>
                        <ul>
                            { props.children.map((value, index)=>(
                                <li key={value.name}><Link as={NavLink} to={value.path} key={value.name}> {value.name} </Link></li>
                            ))}
                        </ul>
                    </Accordion.Body>
                }
            </Accordion.Item>
        </>
    )
}