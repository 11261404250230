import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useUsers({ page, searchState }) {
  const { userId, name, phoneNumber, status } = searchState;

  const newParams = Object.assign(
    {},
    userId !== "" && { userId: searchState.userId },
    name !== "" && { name: searchState.name },
    phoneNumber !== "" && { phoneNumber: searchState.phoneNumber },
    status !== "" && { userStatus: searchState.status }
  );

  const { data: users, isLoading } = useFetch(
    [String(page), userId, name, phoneNumber, status],
    async () => {
      const { data } = await apiClient({
        url: "/users",
        method: "get",
        params: {
          page,
          size: POST_SIZE,
          ...newParams,
        },
      });

      return data;
    }
  );

  return { users, isLoading };
}
