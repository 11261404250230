import { useMemo } from "react";
import { Pagination } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getPageParamsToURL, getPagination } from "../../function/pagination";

const PAGE_SIZE = 10;

export default function DetailPagination({
  currentPage,
  totalPage,
  onPageMove,
}) {
  const location = useLocation();
  const currentPagiNation = useMemo(() => {
    return getPagination(currentPage, totalPage, PAGE_SIZE);
  }, [currentPage, totalPage]);

  const mapedPageIndexList = currentPagiNation.map((pageIndex) => {
    return (
      <Pagination.Item
        key={pageIndex}
        className={pageIndex === parseInt(currentPage) + 1 ? "page on" : "page"}
        onClick={() => onPageMove(pageIndex - 1)}
      >
        {pageIndex}
      </Pagination.Item>
    );
  });

  const validatePage = (page) => {
    if (page <= -1) {
      return true;
    }

    if (page >= totalPage) {
      return true;
    }

    if (page === getPageParamsToURL(location)) {
      return;
    }

    return false;
  };

  const moveFirstPage = (page) => {
    if (validatePage(page)) {
      return;
    }

    onPageMove(page);
    // pageRef.current = page;
  };

  const movePrevPage = (page) => {
    if (validatePage(page)) {
      return;
    }

    onPageMove(page);
    // pageRef.current = page;
  };

  const moveNextPage = (page) => {
    if (validatePage(page)) {
      return;
    }

    onPageMove(page);
    // pageRef.current = page;
  };

  const moveLastPage = (page) => {
    if (validatePage(page)) {
      return;
    }

    onPageMove(page);
    // pageRef.current = page;
  };

  return (
    <>
      {totalPage ? (
        <Pagination className="align-items-center">
          <Pagination.First
            className="PaginationFirst"
            onClick={() => moveFirstPage(0)}
          />
          <Pagination.Prev
            className="PaginationPrev"
            onClick={() => movePrevPage(currentPage - 1)}
          />
          {mapedPageIndexList}
          <Pagination.Next
            className="PaginationNext"
            onClick={() => moveNextPage(parseInt(currentPage) + 1)}
          />
          <Pagination.Last
            className="PaginationLast"
            onClick={() => moveLastPage(totalPage - 1)}
          />
        </Pagination>
      ) : null}
    </>
  );
}
