import React from "react";
import {Link, Navigate} from "react-router-dom";
import {isAuthedUser} from "../../function/common";

const PrivateRoute = ({children}) => {
    // !isAuthedUser() && alert("접근 권한이 없습니다. 로그인 후 다시 시도하십시오.")
    return isAuthedUser() ? children : <Navigate to="/admin/login"/>;
};


export default PrivateRoute;