// import { Link } from "react-router-dom";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUsedCoupon } from "../../hooks/coupon/useUsedCoupon";

const getUsedBtnStatus = (code) => {
  if (code === 1) {
    return false;
  }

  return true;
};

export default function CouponBoard({ coupons, refetch }) {
  const navigate = useNavigate();
  const { mutate, isLoading } = useUsedCoupon({ refetch });

  const handleUsedBtnClick = (qrcode) => {
    if (isLoading) {
      return;
    }

    const answer = window.confirm("해당 식권을 사용처리 하시겠습니까?");

    if (answer) {
      mutate({ qrcode });
    }
  };

  const handleBuyerNameClick = (isNonUser, ownerUserId) => {
    if (isNonUser) {
      return alert("비회원입니다.");
    }

    navigate(`/admin/user/users/${ownerUserId}`);
  };

  const mapedCouponInfos = coupons.content.map((couponInfo) => {
    const {
      id,
      productName,
      storeName,
      buyerName,
      ownerName,
      nonUserName,
      orderNumber,
      qrcode,
      status,
      expireDate,
      usedAt,
      facePrice,
      createdAt,
      isNonUser,
      ownerUserId,
    } = couponInfo;

    return (
      <tr key={id} className="board-row-except">
        <td>
          <Link to={`/admin/coupon/coupons/${id}`}>{id || "-"}</Link>
        </td>
        <td>
          <Link to={`/admin/payment/${orderNumber}`}>{orderNumber || "-"}</Link>
        </td>
        <td
          className="board-link"
          onClick={() => handleBuyerNameClick(isNonUser, ownerUserId)}
        >
          {buyerName ? `${buyerName}(${ownerUserId})` : nonUserName}
        </td>
        <td>{storeName || "-"}</td>
        <td>{productName || "-"}</td>
        <td>{ownerName || nonUserName || "-"}</td>
        <td>{expireDate || "-"}</td>
        <td>{facePrice || "-"}</td>
        <td>{isNonUser ? "N" : "Y"}</td>
        <td>{status ? status.description : "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
        <td>{usedAt ? usedAt.replace("T", " ").substring(0, 16) : "-"}</td>
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleUsedBtnClick(qrcode);
            }}
            disabled={getUsedBtnStatus(status.code)}
          >
            사용 완료
          </button>
        </td>
      </tr>
    );
  });

  return mapedCouponInfos;
}
