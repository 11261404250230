import { Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import Btn from "../../../components/admin/Btn";
import { useReducer, useState } from "react";
import {
  couponReducer,
  initialState,
} from "../../../reducers/coupon/couponReducer";
import { useCoupons } from "../../../hooks/coupon/useCoupons";
import CustomSpinner from "../../../components/common/CustomSpinner";
import CouponBoard from "../../../components/coupon/CouponBoard";
import DetailPagination from "../../../components/common/DetailPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import { transformYYYYMMDD } from "../../../function/date";
import ExportExcelButton from "../../../components/common/ExportExcelButton";

export default function Coupon() {
  const location = useLocation();
  const {
    pageQuery,
    storeNameQuery,
    ownerNameQuery,
    buyerNameQuery,
    statusQuery,
    startCreateDateQuery,
    endCreateDateQuery,
    startCreateTimeQuery,
    endCreateTimeQuery,
    startUseDateQuery,
    endUseDateQuery,
    startUseTimeQuery,
    endUseTimeQuery,
  } = getQueryStringObj();
  const navigate = useNavigate();
  const [isUsedStatus, setIsUsedStatus] = useState(false);
  const [page, setPage] = useState(pageQuery || 0);
  const [showingState, showingDispatch] = useReducer(couponReducer, {
    ...initialState,
    storeName: storeNameQuery || initialState.storeName,
    ownerName: ownerNameQuery || initialState.ownerName,
    buyerName: buyerNameQuery || initialState.buyerName,
    status: statusQuery || initialState.status,
    startTimeOfCreatedAt:
      startCreateDateQuery || initialState.startTimeOfCreatedAt,
    endTimeOfCreatedAt: endCreateDateQuery || initialState.endTimeOfCreatedAt,
    createdStartTime: startCreateTimeQuery || initialState.createdStartTime,
    createdEndTime: endCreateTimeQuery || initialState.createdEndTime,
    startTimeOfUsedAt: startUseDateQuery || initialState.startTimeOfUsedAt,
    endTimeOfUsedAt: endUseDateQuery || initialState.endTimeOfUsedAt,
    usedStartTime: startUseTimeQuery || initialState.usedStartTime,
    usedEndTime: endUseTimeQuery || initialState.usedEndTime,
  });
  const [searchState, searchDispatch] = useReducer(couponReducer, {
    ...initialState,
    storeName: storeNameQuery || initialState.storeName,
    ownerName: ownerNameQuery || initialState.ownerName,
    buyerName: buyerNameQuery || initialState.buyerName,
    status: statusQuery || initialState.status,
    startTimeOfCreatedAt:
      startCreateDateQuery || initialState.startTimeOfCreatedAt,
    endTimeOfCreatedAt: endCreateDateQuery || initialState.endTimeOfCreatedAt,
    createdStartTime: startCreateTimeQuery || initialState.createdStartTime,
    createdEndTime: endCreateTimeQuery || initialState.createdEndTime,
    startTimeOfUsedAt: startUseDateQuery || initialState.startTimeOfUsedAt,
    endTimeOfUsedAt: endUseDateQuery || initialState.endTimeOfUsedAt,
    usedStartTime: startUseTimeQuery || initialState.usedStartTime,
    usedEndTime: endUseTimeQuery || initialState.usedEndTime,
  });

  const { coupons, isLoading, refetch } = useCoupons({
    page,
    searchState,
    isUsedStatus,
  });

  function getQueryString() {
    return location.search
      .replace(
        /\?page=|&storeName=|&ownerName=|&buyerName=|&status=|&startCreateDate=|&endCreateDate=|&startCreateTime=|&endCreateTime=|&startUseDate=|&endUseDate=|&startUseTime=|&endUseTime=/g,
        " "
      )
      .split(" ");
  }

  function getQueryStringObj() {
    const [
      _,
      pageQuery,
      storeNameQuery,
      ownerNameQuery,
      buyerNameQuery,
      statusQuery,
      startCreateDateQuery,
      endCreateDateQuery,
      startCreateTimeQuery,
      endCreateTimeQuery,
      startUseDateQuery,
      endUseDateQuery,
      startUseTimeQuery,
      endUseTimeQuery,
    ] = getQueryString();

    if (
      pageQuery === undefined ||
      storeNameQuery === undefined ||
      ownerNameQuery === undefined ||
      buyerNameQuery === undefined ||
      statusQuery === undefined ||
      startCreateDateQuery === undefined ||
      endCreateDateQuery === undefined ||
      startCreateTimeQuery === undefined ||
      endCreateTimeQuery === undefined ||
      startUseDateQuery === undefined ||
      endUseDateQuery === undefined ||
      startUseTimeQuery === undefined ||
      endUseTimeQuery === undefined
    ) {
      return {
        pageQuery: "",
        storeNameQuery: "",
        ownerNameQuery: "",
        buyerNameQuery: "",
        statusQuery: "",
        startCreateDateQuery: new Date(),
        endCreateDateQuery: new Date(),
        startCreateTimeQuery: "0",
        endCreateTimeQuery: "23",
        startUseDateQuery: new Date(),
        endUseDateQuery: new Date(),
        startUseTimeQuery: "0",
        endUseTimeQuery: "23",
      };
    }

    return {
      pageQuery: decodeURI(pageQuery),
      storeNameQuery: decodeURI(storeNameQuery),
      ownerNameQuery: decodeURI(ownerNameQuery),
      buyerNameQuery: decodeURI(buyerNameQuery),
      statusQuery: decodeURI(statusQuery),
      startCreateDateQuery: new Date(decodeURI(startCreateDateQuery)),
      endCreateDateQuery: new Date(decodeURI(endCreateDateQuery)),
      startCreateTimeQuery: decodeURI(startCreateTimeQuery),
      endCreateTimeQuery: decodeURI(endCreateTimeQuery),
      startUseDateQuery: new Date(decodeURI(startUseDateQuery)),
      endUseDateQuery: new Date(decodeURI(endUseDateQuery)),
      startUseTimeQuery: decodeURI(startUseTimeQuery),
      endUseTimeQuery: decodeURI(endUseTimeQuery),
    };
  }

  const setPageStatus = useCallback(() => {
    const [
      _,
      page,
      storeName,
      ownerName,
      buyerName,
      status,
      startCreateDate,
      endCreateDate,
      startCreateTime,
      endCreateTime,
      startUseDate,
      endUseDate,
      startUseTime,
      endUseTime,
    ] = getQueryString();

    if (!page) {
      setPage(0);
      showingDispatch({ type: "reseted" });
      searchDispatch({ type: "reseted" });
      return;
    }

    const getPayload = () => {
      return {
        storeName: decodeURI(storeName),
        ownerName: decodeURI(ownerName),
        buyerName: decodeURI(buyerName),
        status: decodeURI(status),
        startTimeOfCreatedAt: new Date(decodeURI(startCreateDate)),
        endTimeOfCreatedAt: new Date(decodeURI(endCreateDate)),
        createdStartTime: decodeURI(startCreateTime),
        createdEndTime: decodeURI(endCreateTime),
        startTimeOfUsedAt: new Date(decodeURI(startUseDate)),
        endTimeOfUsedAt: new Date(decodeURI(endUseDate)),
        usedStartTime: decodeURI(startUseTime),
        usedEndTime: decodeURI(endUseTime),
      };
    };

    setPage(page);
    showingDispatch({
      type: "moved",
      payload: getPayload(),
    });
    searchDispatch({
      type: "moved",
      payload: getPayload(),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  if (!coupons) {
    return <CustomSpinner />;
  }

  const getNavigateStr = (state, page = 0) => {
    const {
      storeName,
      ownerName,
      buyerName,
      status,
      startTimeOfCreatedAt,
      endTimeOfCreatedAt,
      createdStartTime,
      createdEndTime,
      startTimeOfUsedAt,
      endTimeOfUsedAt,
      usedStartTime,
      usedEndTime,
    } = state;

    return `?page=${page}&storeName=${storeName}&ownerName=${ownerName}&buyerName=${buyerName}&status=${status}&startCreateDate=${transformYYYYMMDD(
      startTimeOfCreatedAt
    )}&endCreateDate=${transformYYYYMMDD(
      endTimeOfCreatedAt
    )}&startCreateTime=${createdStartTime}&endCreateTime=${createdEndTime}&startUseDate=${transformYYYYMMDD(
      startTimeOfUsedAt
    )}&endUseDate=${transformYYYYMMDD(
      endTimeOfUsedAt
    )}&startUseTime=${usedStartTime}&endUseTime=${usedEndTime}`;
  };

  const searchStoreInfo = () => {
    if (isLoading) {
      return;
    }

    searchDispatch({ type: "moved", payload: showingState });
    navigate(getNavigateStr(showingState));
  };

  const handleSearchBtnClick = () => {
    searchStoreInfo();
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    searchStoreInfo();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">교환권 목록</h1>
              </Col>
            </Row>
            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{coupons.totalElements}</h5>
              </Col>
              <Col xs={12} lg={"auto"}>
                <ExportExcelButton
                  btnText="교환권 목록 전체 엑셀 다운로드"
                  exportTargetName="교환권 목록"
                  apiUrl={"/all-coupons"}
                  sheetColumn={[
                    { header: "id", key: "id" },
                    { header: "상품명", key: "productName" },
                    { header: "상점명", key: "storeName" },
                    { header: "구매자명", key: "buyerName" },
                    { header: "구매자id", key: "buyerUserId" },
                    { header: "소유자id", key: "ownerUserId" },
                    { header: "소유자명", key: "ownerName" },
                    { header: "비회원명", key: "nonUserName" },
                    { header: "주문번호", key: "orderNumber" },
                    { header: "qrcode 번호", key: "qrcode" },
                    { header: "사용상태", key: "status" },
                    { header: "유효기간", key: "expireDate" },
                    { header: "사용일자", key: "usedAt" },
                    { header: "액면가액", key: "facePrice" },
                    { header: "생성일자", key: "createdAt" },
                    { header: "비회원여부", key: "isNonUser" },
                  ]}
                  dataFormatter={(data) => {
                    return data.map((item) => ({
                      ...item,
                      status: item.status?.name,
                      isNonUser: item.isNonUser ? "비회원" : "회원",
                    }));
                  }}
                />
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "820px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">구매자명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="buyerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.buyerName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_buyerName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.storeName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_storeName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">소유자명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.ownerName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_ownerName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">비회원명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="nonUserName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.nonUserName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_nonUserName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">교환권 상태</h5>
                      </Col>
                      <Col>
                        <Form className="form-radio">
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                                checked={showingState.status === ""}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="미사용"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="NOT_USED"
                                checked={showingState.status === "NOT_USED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="사용완료"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="USED"
                                checked={showingState.status === "USED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="결제취소"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="CANCELED"
                                checked={showingState.status === "CANCELED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="환불"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-5`}
                                value="REFUND"
                                checked={showingState.status === "REFUND"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="환불예정"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-6`}
                                value="ON_REFUND"
                                checked={showingState.status === "ON_REFUND"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="취소예정"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-7`}
                                value="TO_BE_CANCELLED"
                                checked={
                                  showingState.status === "TO_BE_CANCELLED"
                                }
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    lg={"auto"}
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Row className="p-1 align-items-center justify-content-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">생성일</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={showingState.startTimeOfCreatedAt}
                          onChange={(date) =>
                            showingDispatch({
                              type: "changed_createDate",
                              start: date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <h5 className="mb-0"> ~ </h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={showingState.endTimeOfCreatedAt}
                          onChange={(date) =>
                            showingDispatch({
                              type: "changed_createDate",
                              end: date,
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="align-items-center mt-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">시간대 설정</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <select
                          value={showingState.createdStartTime}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_createTime",
                              start: target.value,
                            })
                          }
                        >
                          <option value="0">00:00</option>
                          <option value="1">01:00</option>
                          <option value="2">02:00</option>
                          <option value="3">03:00</option>
                          <option value="4">04:00</option>
                          <option value="5">05:00</option>
                          <option value="6">06:00</option>
                          <option value="7">07:00</option>
                          <option value="8">08:00</option>
                          <option value="9">09:00</option>
                          <option value="10">10:00</option>
                          <option value="11">11:00</option>
                          <option value="12">12:00</option>
                          <option value="13">13:00</option>
                          <option value="14">14:00</option>
                          <option value="15">15:00</option>
                          <option value="16">16:00</option>
                          <option value="17">17:00</option>
                          <option value="18">18:00</option>
                          <option value="19">19:00</option>
                          <option value="20">20:00</option>
                          <option value="21">21:00</option>
                          <option value="22">22:00</option>
                          <option value="23">23:00</option>
                        </select>
                        ~
                        <select
                          value={showingState.createdEndTime}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_createTime",
                              end: target.value,
                            })
                          }
                        >
                          <option value="0">00:00</option>
                          <option value="1">01:00</option>
                          <option value="2">02:00</option>
                          <option value="3">03:00</option>
                          <option value="4">04:00</option>
                          <option value="5">05:00</option>
                          <option value="6">06:00</option>
                          <option value="7">07:00</option>
                          <option value="8">08:00</option>
                          <option value="9">09:00</option>
                          <option value="10">10:00</option>
                          <option value="11">11:00</option>
                          <option value="12">12:00</option>
                          <option value="13">13:00</option>
                          <option value="14">14:00</option>
                          <option value="15">15:00</option>
                          <option value="16">16:00</option>
                          <option value="17">17:00</option>
                          <option value="18">18:00</option>
                          <option value="19">19:00</option>
                          <option value="20">20:00</option>
                          <option value="21">21:00</option>
                          <option value="22">22:00</option>
                          <option value="23">23:00</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">사용일 유무</h5>
                      </Col>
                      <Col>
                        <Form className="form-radio">
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                inline
                                label="유"
                                name="Filter-2"
                                type={type}
                                id={`inline-${type}-8`}
                                value=""
                                checked={isUsedStatus}
                                onChange={() => setIsUsedStatus(true)}
                              />
                              <Form.Check
                                inline
                                label="무"
                                name="Filter-2"
                                type={type}
                                id={`inline-${type}-9`}
                                value="NOT_USED"
                                checked={!isUsedStatus}
                                onChange={() => setIsUsedStatus(false)}
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  {isUsedStatus && (
                    <Col xs={12}>
                      <Row className="p-1 align-items-center justify-content-center mt-4">
                        <Col xs={"auto"}>
                          <h5 className="mb-0 fw-bold">사용일</h5>
                        </Col>
                        <Col xs={"auto"}>
                          <DatePicker
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={showingState.startTimeOfUsedAt}
                            onChange={(date) =>
                              showingDispatch({
                                type: "changed_useDate",
                                start: date,
                              })
                            }
                          />
                        </Col>
                        <Col xs={"auto"}>
                          <h5 className="mb-0"> ~ </h5>
                        </Col>
                        <Col xs={"auto"}>
                          <DatePicker
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={showingState.endTimeOfUsedAt}
                            onChange={(date) =>
                              showingDispatch({
                                type: "changed_useDate",
                                end: date,
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center mt-4">
                        <Col xs={"auto"}>
                          <h5 className="mb-0 fw-bold">시간대 설정</h5>
                        </Col>
                        <Col xs={"auto"}>
                          <select
                            value={showingState.usedStartTime}
                            onChange={({ target }) =>
                              showingDispatch({
                                type: "changed_useTime",
                                start: target.value,
                              })
                            }
                          >
                            <option value="0">00:00</option>
                            <option value="1">01:00</option>
                            <option value="2">02:00</option>
                            <option value="3">03:00</option>
                            <option value="4">04:00</option>
                            <option value="5">05:00</option>
                            <option value="6">06:00</option>
                            <option value="7">07:00</option>
                            <option value="8">08:00</option>
                            <option value="9">09:00</option>
                            <option value="10">10:00</option>
                            <option value="11">11:00</option>
                            <option value="12">12:00</option>
                            <option value="13">13:00</option>
                            <option value="14">14:00</option>
                            <option value="15">15:00</option>
                            <option value="16">16:00</option>
                            <option value="17">17:00</option>
                            <option value="18">18:00</option>
                            <option value="19">19:00</option>
                            <option value="20">20:00</option>
                            <option value="21">21:00</option>
                            <option value="22">22:00</option>
                            <option value="23">23:00</option>
                          </select>
                          ~
                          <select
                            value={showingState.usedEndTime}
                            onChange={({ target }) =>
                              showingDispatch({
                                type: "changed_useTime",
                                end: target.value,
                              })
                            }
                          >
                            <option value="0">00:00</option>
                            <option value="1">01:00</option>
                            <option value="2">02:00</option>
                            <option value="3">03:00</option>
                            <option value="4">04:00</option>
                            <option value="5">05:00</option>
                            <option value="6">06:00</option>
                            <option value="7">07:00</option>
                            <option value="8">08:00</option>
                            <option value="9">09:00</option>
                            <option value="10">10:00</option>
                            <option value="11">11:00</option>
                            <option value="12">12:00</option>
                            <option value="13">13:00</option>
                            <option value="14">14:00</option>
                            <option value="15">15:00</option>
                            <option value="16">16:00</option>
                            <option value="17">17:00</option>
                            <option value="18">18:00</option>
                            <option value="19">19:00</option>
                            <option value="20">20:00</option>
                            <option value="21">21:00</option>
                            <option value="22">22:00</option>
                            <option value="23">23:00</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox mt-4">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>교환권 No.</th>
                    <th>주문 번호</th>
                    <th>구매자명</th>
                    <th>가맹점명</th>
                    <th>상품명</th>
                    <th>소유자명</th>
                    <th>유효기간</th>
                    <th>액면가</th>
                    <th>회원유무</th>
                    <th>상태</th>
                    <th>생성일자</th>
                    <th>사용일자</th>
                    <th>사용처리 하기</th>
                  </tr>
                </thead>
                <tbody>
                  <CouponBoard coupons={coupons} refetch={refetch} />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={coupons.totalPages}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
