export const getPagination = (currentPage, totalPage, pageSize) => {
  const totalPagination = Array.from(
    {
      length: totalPage,
    },
    (_, index) => index + 1
  );

  const firstPage = currentPage - (currentPage % pageSize);
  const lastPage = currentPage - (currentPage % pageSize) + pageSize;

  return totalPagination.slice(firstPage, lastPage);
};

export const getPageByLocation = ({ search }) => {
  const pageRegex = /\?page=(\d+)/;
  const matchedPage = search.match(pageRegex);

  if (!matchedPage) {
    return 0;
  }

  const [_, result] = matchedPage;

  return parseInt(result);
};

export const getPageParamsToURL = ({ search }) => {
  const pageRegex = /\?page=(\d)+/;

  if (!search) {
    return 0;
  }

  const [_, matchedPage] = search.match(pageRegex);

  return parseInt(matchedPage);
};
