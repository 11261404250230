export const initialState = {
  userId: "",
  name: "",
  phoneNumber: "",
  status: "",
};

export function userReducer(state, action) {
  switch (action.type) {
    case "changed_userId":
      return {
        ...state,
        userId: action.payload,
      };
    case "changed_name":
      return {
        ...state,
        name: action.payload,
      };
    case "changed_phoneNumber":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "changed_status":
      return {
        ...state,
        status: action.payload,
      };
    case "reseted":
      return { ...initialState };
    case "moved":
      return { ...action.payload };
    default:
      return Error(`Unknown action type: ${action.type}`);
  }
}
