export default function Switch( props ){

    const customCSS = {
       
    }

    return (
        <div className="form-check d-flex align-items-center ps-0">
            <input style={customCSS} className="form-check-input m-0 me-1" type="checkbox" id="mySwitch" name={props.name} value={props.val} defaultChecked={props.checked !== undefined ? props.checked : false} />
            { props.label !== undefined ? <label className="form-check-label" htmlFor="mySwitch">{props.label}</label> : null }
        </div>
    )
}