export const initialState = {
  storeName: "",
  buyerName: "",
  startDate: new Date(),
  startTime: "0",
  userId: "",
  endDate: new Date(),
  endTime: "23",
  status: "",
  phoneNumber: "",
  orderNumber: "",
};

export function paymentReducer(state, action) {
  switch (action.type) {
    case "changed_storeName":
      return { ...state, storeName: action.payload };
    case "changed_buyerName":
      return { ...state, buyerName: action.payload };
    case "changed_phoneNumber":
      return { ...state, phoneNumber: action.payload };
    case "changed_orderNumber":
      return { ...state, orderNumber: action.payload };
    case "changed_userId":
      return { ...state, userId: action.payload };
    case "changed_status":
      return { ...state, status: action.payload };
    case "changed_date":
      if (action.start) {
        return { ...state, startDate: action.start };
      }

      return { ...state, endDate: action.end };
    case "changed_time":
      if (action.start) {
        return { ...state, startTime: action.start };
      }

      return { ...state, endTime: action.end };
    case "reseted":
      return { ...initialState };
    case "moved":
      return { ...action.payload };
    default:
      return Error(`Unknown action type ${action.type}`);
  }
}
