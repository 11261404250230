import axios from "axios";
import sha256 from "sha256";
import { useMutation } from "../common/useMutation";

export function useLogin() {
  const { mutate, isLoading } = useMutation(
    async (params) => {
      return await axios({
        url: `${process.env.REACT_APP_API}auth/login`,
        method: "post",
        data: { ...params, password: sha256(params.password) },
      });
    },
    {
      onSuccess: ({ data }) => {
        const { accessToken, refreshToken } = data.loginToken;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        window.location.replace("/admin/home");
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { mutate, isLoading };
}
