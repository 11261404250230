import React, { useState } from "react";
import Btn from "../admin/Btn";
import { Col, Row } from "react-bootstrap";
import { apiClient } from "../../api/apiClient";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const ExportExcelButton = ({
  sheetColumn = [],
  sheetName,
  dataFormatter,
  customFormatter,
  exportTargetName = "result",
  apiUrl,
  params,
  btnText = "엑셀 다운로드",
}) => {
  const worksheetName = sheetName ?? exportTargetName ?? "result";
  const [loading, setLoading] = useState(false);
  const getExcel = async () => {
    if (loading) return;
    setLoading(true);
    const { data } = await apiClient({
      url: apiUrl,
      method: "get",
      params,
    });

    if (!data) return;
    const result = dataFormatter ? dataFormatter(data) : data;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(worksheetName);
    worksheet.columns = sheetColumn;
    const keyValue = sheetColumn.map((column) => column.key);
    for (let row of result) {
      let tempObj = {};
      keyValue.forEach(
        (key) =>
          (tempObj[key] = customFormatter
            ? customFormatter(row, key)
            : row[key])
      );
      worksheet.addRow(tempObj);
    }
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${exportTargetName}_${moment().format("YYYY.MM.DD")}.xlsx`);
    setLoading(false);
  };

  return (
    <Row className="align-items-center justify-content-center">
      <Col xs={"auto"}>
        <Btn
          type="API"
          btntext={btnText}
          onClick={getExcel}
          disabled={loading}
        />
      </Col>
    </Row>
  );
};

export default ExportExcelButton;
