import { Link, useNavigate } from "react-router-dom";

export default function PaymentBoard({ payments }) {
  const navigate = useNavigate();

  const handleBuyerNameClick = (buyerId) => {
    if (!buyerId) {
      return alert("비회원입니다.");
    }

    navigate(`/admin/user/users/${buyerId}`);
  };

  const mapedPaymentInfos = payments.content.map((paymentInfo) => {
    const {
      completedAt,
      paymentPrice,
      buyerId,
      buyerName,
      buyerPhoneNumber,
      payMethod,
      paymentStatus,
      canceledAt,
      orderNumber,
    } = paymentInfo;

    return (
      <tr className="board-row-except" key={orderNumber}>
        <td>
          <Link to={`/admin/payment/${orderNumber}`}>{orderNumber || "-"}</Link>
        </td>
        <td
          className="board-link"
          onClick={() => handleBuyerNameClick(buyerId)}
        >
          {buyerId ? `${buyerName}(${buyerId})` : "-"}
        </td>
        <td>{buyerPhoneNumber || "-"}</td>
        <td>{payMethod || "-"}</td>
        <td>{paymentPrice || "-"}</td>
        <td>{paymentStatus ? paymentStatus.description : "-"}</td>
        <td>
          {completedAt ? completedAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
        <td>
          {canceledAt ? canceledAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
      </tr>
    );
  });

  return mapedPaymentInfos;
}
