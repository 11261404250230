import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useState, useEffect, useCallback, useReducer } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import DetailPagination from "../../../components/common/DetailPagination";
import SettlementBoard from "../../../components/settlement/SettlementBoard";
import { useExcel } from "../../../hooks/common/useExcel";
import { useCompletedSettlement } from "../../../hooks/settlement/useCompletedSettlement";
import { useProcessedSettlement } from "../../../hooks/settlement/useProcessedSettlement";
import { useSettlements } from "../../../hooks/settlement/useSettlements";
import {
  initialState,
  settlementReducer,
} from "../../../reducers/settlement/settlementReducer";
import { useCompletedSettlementProcessing } from "../../../hooks/settlement/useCompletedSettlementProcessing";

export default function Settlement() {
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(0);
  const [showingState, showingDispatch] = useReducer(
    settlementReducer,
    initialState
  );
  const [searchState, searchDispatch] = useReducer(
    settlementReducer,
    initialState
  );

  const [isAllChecked, setIsAllChecked] = useState(false);
  const {
    settlements,
    isSettlementsLoading,
    checkSettlements,
    setCheckSettlements,
    refetch,
    totalElements,
    totalPages,
  } = useSettlements({
    page,
    searchState,
  });
  const { mutate, isLoading } = useCompletedSettlement({
    refetch,
    setIsAllChecked,
  });
  const { processMutate, isProcessLoading } = useProcessedSettlement({
    refetch,
    setIsAllChecked,
  });
  const { completedProcessMutate, isCompletedProcessLoading } =
    useCompletedSettlementProcessing({
      refetch,
      setIsAllChecked,
    });

  const { mutate: taxMutate, isLoading: isTaxLoading } = useExcel(
    "/settlements/excel/tax-bill"
  );
  const { mutate: transferMutate, isLoading: isTrasferLoading } = useExcel(
    "/settlements/excel/transfer"
  );

  const setPageStatus = useCallback(() => {
    const [_, page, storeName] = location.search
      .replace(/\?page=|&storeName=/g, " ")
      .split(" ");

    if (!page) {
      setPage(0);
      showingDispatch({ type: "reseted" });
      searchDispatch({ type: "reseted" });
      return;
    }

    setPage(page);
    showingDispatch({
      type: "moved",
      payload: {
        storeName: decodeURI(storeName),
      },
    });
    searchDispatch({
      type: "moved",
      payload: {
        storeName: decodeURI(storeName),
      },
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  useEffect(() => {
    if (!checkSettlements) {
      return;
    }

    const filteredSettlements = checkSettlements.filter(
      ({ settleStatus }) => settleStatus.code !== 2
    );

    if (filteredSettlements.find(({ isChecked }) => !isChecked)) {
      return setIsAllChecked(false);
    }

    if (
      filteredSettlements.length ===
      checkSettlements.filter(({ isChecked }) => isChecked).length
    ) {
      return setIsAllChecked(true);
    }
  }, [checkSettlements]);

  if (!settlements) {
    return <CustomSpinner />;
  }

  const getNavigateStr = (state, page = 0) => {
    const { storeName } = state;

    return `?page=${page}&storeName=${storeName}`;
  };

  const searchSettlementInfo = () => {
    if (isSettlementsLoading) {
      return;
    }

    searchDispatch({
      type: "moved",
      payload: {
        storeName: showingState.storeName,
      },
    });
    navigate(getNavigateStr(showingState));
  };

  const handleSearchBtnClick = () => {
    searchSettlementInfo();
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    searchSettlementInfo();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  const handleSettlementCompletedBtnClick = () => {
    if (!checkSettlements.filter(({ isChecked }) => isChecked).length) {
      return;
    }

    if (isLoading) {
      return;
    }

    const result = window.confirm("정산 완료 처리 하시겠습니까?");

    if (!result) {
      return;
    }

    const disabeldNotSettlements = settlements.filter(
      ({ settleStatus }) => settleStatus.code !== 2
    );

    const checkedSettlements = disabeldNotSettlements.filter(
      ({ isChecked }) => isChecked
    );

    if (!checkedSettlements.length) {
      return;
    }

    const filteredIdSettlements = checkedSettlements.map(({ id }) => id);

    mutate({ settleIds: filteredIdSettlements });
  };

  const handleSettlementProcessedBtnClick = () => {
    if (!checkSettlements.filter(({ isChecked }) => isChecked).length) {
      return;
    }

    if (isProcessLoading) {
      return;
    }

    const result = window.prompt(
      "정산 지급일을 입력해주십시오. ex)2023-03-28\n정산대행 요청은 취소가 불가능합니다."
    );
    const regexp = new RegExp(/^\d{4}-\d{2}-\d{2}$/);

    if (!regexp.test(result)) {
      alert("형식을 지켜주십시오. ex)2023-03-28");
      return;
    }

    const disabeldNotSettlements = settlements.filter(
      ({ settleStatus }) => settleStatus.code !== 2
    );

    const checkedSettlements = disabeldNotSettlements.filter(
      ({ isChecked }) => isChecked
    );

    if (!checkedSettlements.length) {
      return;
    }

    const filteredIdSettlements = checkedSettlements.map(({ id }) => id);

    processMutate({ settleIds: filteredIdSettlements, payoutDate: result });
  };

  const handleSettlementCompletedProcessingBtnClick = () => {
    if (!checkSettlements.filter(({ isChecked }) => isChecked).length) {
      return;
    }

    if (isCompletedProcessLoading) {
      return;
    }

    const disabeldNotSettlements = settlements.filter(
      ({ settleStatus }) => settleStatus.code !== 2
    );

    const checkedSettlements = disabeldNotSettlements.filter(
      ({ isChecked }) => isChecked
    );

    if (!checkedSettlements.length) {
      return;
    }

    const filteredIdSettlements = checkedSettlements.map(({ id }) => id);

    completedProcessMutate({ settleIds: filteredIdSettlements });
  };

  const handleExcelDonwloadBtnClick = (excelFunc, text) => {
    const result = window.prompt(
      `${text} 입력해주세요. ex)2023,1`,
      `${new Date().getFullYear()},${new Date().getMonth() + 1}`
    );

    if (!result) {
      return;
    }

    const [year, month] = result.split(",");

    if (!year || !month) {
      alert("양식에 맞춰 입력해주십시오.");
      return;
    }

    excelFunc(year.trim(), month.trim());
  };

  const downloadTaxExcel = (year, month) => {
    taxMutate({ year, month });
  };

  const downloadTransferExcel = (year, weekOfYear) => {
    transferMutate({ year, weekOfYear });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">정산 내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
              <Row className="p-1 justify-content-end">
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="세금계산서 발급 엑셀 다운"
                    onClick={() =>
                      handleExcelDonwloadBtnClick(
                        downloadTaxExcel,
                        "년도와 월을"
                      )
                    }
                  />
                </Col>
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="이체 양식"
                    onClick={() =>
                      handleExcelDonwloadBtnClick(
                        downloadTransferExcel,
                        "년도와 주차를"
                      )
                    }
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점 이름</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.storeName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_storeName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center mt-3">
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "8px",
                  gap: "8px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSettlementProcessedBtnClick}
                >
                  정산 대행
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSettlementCompletedProcessingBtnClick}
                >
                  정산 대행 완료
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSettlementCompletedBtnClick}
                >
                  정산 완료
                </button>
              </div>
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "30px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAllChecked}
                              onChange={() => {
                                setIsAllChecked((prev) => !prev);
                                setCheckSettlements((prev) =>
                                  prev.map((item) => {
                                    if (item.settleStatus.code === 2) {
                                      return item;
                                    }

                                    return {
                                      ...item,
                                      isChecked: !isAllChecked,
                                    };
                                  })
                                );
                              }}
                            />
                          }
                          label="전체 선택"
                        />
                      </FormGroup>
                    </th>
                    <th>정산 No.</th>
                    <th>가맹점 이름</th>
                    <th>년도</th>
                    <th>주차</th>
                    <th>시작 날짜</th>
                    <th>끝 날짜</th>
                    <th>정산일</th>
                    <th>판매 금액</th>
                    <th>정산 금액</th>
                    <th>수수료</th>
                    <th>판매건수</th>
                    <th>정산 예정일</th>
                    <th>정산 상태</th>
                    <th>정산 대행 상태</th>
                  </tr>
                </thead>
                <tbody>
                  <SettlementBoard
                    settlements={settlements}
                    setCheckSettlements={setCheckSettlements}
                  />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPages}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {(isTaxLoading || isTrasferLoading) && <CustomSpinner type="backdrop" />}
    </>
  );
}
