import { getTodayTypeYYYYMMDD } from "../../components/common/date";
import { useFetchs } from "../common/useFetchs";

export function useToday({ referenceDate, periodSearchPatternType, storeId }) {
  const [today, yesterDay] = referenceDate;

  const { data: todayInfo } = useFetchs(
    [],
    [
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: today,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: yesterDay,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/payment-methods",
        method: "get",
        params: {
          startDate: getTodayTypeYYYYMMDD(),
          periodSearchPatternType,
          storeId,
        },
      },
    ],
    {
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { todayInfo };
}
