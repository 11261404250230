import { Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import { useCallback, useEffect, useState, useReducer } from "react";
import { useUsers } from "../../../hooks/user/useUsers";
import CustomSpinner from "../../../components/common/CustomSpinner";
import UserBoard from "../../../components/user/UserBoard";
import DetailPagination from "../../../components/common/DetailPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { initialState, userReducer } from "../../../reducers/user/userReducer";
import ExportExcelButton from "../../../components/common/ExportExcelButton";
import moment from "moment";

export default function User() {
  const location = useLocation();
  const {
    pageQuery,
    userIdQuery,
    nameQuery,
    phoneNumberQuery,
    userStatusQuery,
  } = getQueryStringObj();
  const navigate = useNavigate();
  const [page, setPage] = useState(pageQuery || 0);
  const [showingState, showingDispatch] = useReducer(userReducer, {
    ...initialState,
    userId: userIdQuery || initialState.userId,
    name: nameQuery || initialState.name,
    phoneNumber: phoneNumberQuery || initialState.phoneNumber,
    status: userStatusQuery || initialState.status,
  });
  const [searchState, searchDispatch] = useReducer(userReducer, {
    ...initialState,
    userId: userIdQuery || initialState.userId,
    name: nameQuery || initialState.name,
    phoneNumber: phoneNumberQuery || initialState.phoneNumber,
    status: userStatusQuery || initialState.status,
  });
  const { users, isLoading } = useUsers({
    page,
    searchState,
  });

  function getQueryString() {
    return location.search
      .replace(/\?page=|&userId=|&name=|&phoneNumber=|&userStatus=/g, " ")
      .split(" ");
  }

  function getQueryStringObj() {
    const [
      _,
      pageQuery,
      userIdQuery,
      nameQuery,
      phoneNumberQuery,
      userStatusQuery,
    ] = getQueryString();

    if (
      pageQuery === undefined ||
      userIdQuery === undefined ||
      nameQuery === undefined ||
      phoneNumberQuery === undefined ||
      userStatusQuery === undefined
    ) {
      return {
        userIdQuery: "",
        pageQuery: "",
        nameQuery: "",
        phoneNumberQuery: "",
        userStatusQuery: "",
      };
    }

    return {
      userIdQuery: decodeURI(userIdQuery),
      pageQuery: decodeURI(pageQuery),
      nameQuery: decodeURI(nameQuery),
      phoneNumberQuery: decodeURI(phoneNumberQuery),
      userStatusQuery: decodeURI(userStatusQuery),
    };
  }

  const setPageStatus = useCallback(() => {
    const [_, page, userId, name, phoneNumber, userStatus] = getQueryString();

    if (!page) {
      setPage(0);
      showingDispatch({ type: "reseted" });
      searchDispatch({ type: "reseted" });
      return;
    }

    const getPayload = () => {
      return {
        userId: decodeURI(userId),
        name: decodeURI(name),
        phoneNumber: decodeURI(phoneNumber),
        status: decodeURI(userStatus),
      };
    };

    setPage(page);
    showingDispatch({
      type: "moved",
      payload: getPayload(),
    });
    searchDispatch({
      type: "moved",
      payload: getPayload(),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  if (!users) {
    return <CustomSpinner />;
  }

  const getNavigateStr = (state, page = 0) => {
    const { userId, name, phoneNumber, status } = state;

    return `?page=${page}&userId=${userId}&name=${name}&phoneNumber=${phoneNumber}&userStatus=${status}`;
  };

  const searchUserInfo = () => {
    if (isLoading) {
      return;
    }

    showingDispatch({ type: "moved", payload: showingState });
    navigate(getNavigateStr(showingState));
  };

  const handleSearchBtnClick = () => {
    searchUserInfo();
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    searchUserInfo();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">회원 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{users.totalElements}</h5>
              </Col>
              <Col xs={12} lg={"auto"}>
                <ExportExcelButton
                  btnText="회원 목록 전체 엑셀 다운로드"
                  exportTargetName="회원 목록"
                  apiUrl={"/all-users"}
                  sheetColumn={[
                    {
                      header: "id",
                      key: "id",
                    },
                    {
                      header: "이름",
                      key: "name",
                    },
                    {
                      header: "전화번호",
                      key: "phoneNumber",
                    },
                    {
                      header: "상태",
                      key: "userStatus",
                    },
                    {
                      header: "로그인 종류",
                      key: "oauthType",
                    },
                    {
                      header: "이메일",
                      key: "email",
                    },
                    {
                      header: "마케팅 동의",
                      key: "marketingConsent",
                    },
                    {
                      header: "기기 정보",
                      key: "deviceInfo",
                    },
                    {
                      header: "생성일자",
                      key: "createdAt",
                    },
                  ]}
                  dataFormatter={(data) => {
                    return data.map((item) => ({
                      ...item,
                      userStatus: item.userStatus?.description,
                      marketingConsent: item.marketingConsent
                        ? "동의"
                        : "동의 안함",
                    }));
                  }}
                />
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={12} className="mb-4">
                        <Row className="align-items-center Formbox">
                          <Col xs={"auto"}>
                            <h5 className="mb-0 fw-bold">회원번호</h5>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              id="ownerName"
                              aria-describedby="passwordHelpBlock"
                              value={showingState.userId}
                              onChange={({ target }) =>
                                showingDispatch({
                                  type: "changed_userId",
                                  payload: target.value,
                                })
                              }
                              onKeyDown={handleInputKeyDown}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.name}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_name",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원 전화번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.phone}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_phoneNumber",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원 상태</h5>
                      </Col>
                      <Col>
                        <Form className="form-radio">
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                                checked={showingState.status === ""}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="가입"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="JOINED"
                                checked={showingState.status === "JOINED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="밴"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="BLOCKED"
                                checked={showingState.status === "BLOCKED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="탈퇴"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="WITHDRAWAL"
                                checked={showingState.status === "WITHDRAWAL"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="대기"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-5`}
                                value="WAITING"
                                checked={showingState.status === "WAITING"}
                                onChange={({ target }) => {
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  });
                                }}
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>회원번호</th>
                    <th>이름</th>
                    <th>이메일</th>
                    <th>전화 번호</th>
                    <th>기기 정보</th>
                    <th>상태</th>
                    <th>로그인 종류</th>
                    <th>생성일자</th>
                  </tr>
                </thead>
                <tbody>
                  <UserBoard users={users} />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={users.totalPages}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
