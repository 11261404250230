import { apiClient } from "../../api/apiClient";
import { getMonthTypeYYYYMM } from "../../components/common/date";
import { useFetchs } from "../common/useFetchs";

export function useMonthly({
  referenceDate,
  periodSearchPatternType,
  storeId,
}) {
  const [thisMonth, lastMonth] = referenceDate;

  const { data: monthInfo, setState } = useFetchs(
    [],
    [
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: thisMonth,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: lastMonth,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/payment-methods",
        method: "get",
        params: {
          startDate: getMonthTypeYYYYMM(),
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/statistics/payment-prices",
        method: "get",
        params: {
          startDate: getMonthTypeYYYYMM(),
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/statistics/return-customer-count",
        method: "get",
        params: {
          startDate: getMonthTypeYYYYMM(),
          periodSearchPatternType,
        },
      },
    ],
    {
      onError: ({ response }) => {
        const { data } = response;

        if (data.code === "-510") {
          Promise.all(
            [
              {
                url: "statistics",
                method: "lastMonth",
                params: {
                  startDate: thisMonth,
                  periodSearchPatternType,
                  storeId: 1,
                },
              },
              {
                url: "statistics",
                method: "get",
                params: {
                  startDate: thisMonth,
                  periodSearchPatternType,
                  storeId,
                },
              },
              {
                url: "/payment-methods",
                method: "get",
                params: {
                  startDate: getMonthTypeYYYYMM(),
                  periodSearchPatternType,
                  storeId,
                },
              },
              {
                url: "/statistics/payment-prices",
                method: "get",
                params: {
                  startDate: getMonthTypeYYYYMM(),
                  periodSearchPatternType,
                  storeId,
                },
              },
              {
                url: "/statistics/return-customer-count",
                method: "get",
                params: {
                  startDate: getMonthTypeYYYYMM(),
                  periodSearchPatternType,
                },
              },
            ].map((fetcherObj) => apiClient(fetcherObj))
          ).then((response) => {
            const mapedResponsed = response.map(({ data }) => data);
            setState((prev) => ({ ...prev, data: mapedResponsed }));
          });

          return;
        }

        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { monthInfo };
}
