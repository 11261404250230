import { useLocation } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { getParamsDate } from "../../function/date";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useNonUsers({ page, searchState }) {
  const location = useLocation();
  const { name, phoneNumber, startDate, endDate } = searchState;

  const params = Object.assign(
    {
      page,
      size: POST_SIZE,
      startDate: getParamsDate(startDate),
      endDate: getParamsDate(endDate),
    },
    name !== "" && { name },
    phoneNumber !== "" && { phoneNumber }
  );

  const { data: nonUsers, isLoading } = useFetch(
    [
      String(page),
      name,
      phoneNumber,
      getParamsDate(startDate),
      getParamsDate(endDate),
    ],
    async () => {
      const { data } = await apiClient({
        url: "/non-users",
        method: "get",
        params,
      });

      return data;
    }
  );

  return { nonUsers, isLoading };
}
