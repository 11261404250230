import { getYearTypeYYYY } from "../../components/common/date";
import { useFetchs } from "../common/useFetchs";

export function useYearly({ referenceDate, periodSearchPatternType, storeId }) {
  const [thisYear, lastYear] = referenceDate;

  const { data: yearInfo } = useFetchs(
    [],
    [
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: thisYear,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "statistics",
        method: "get",
        params: {
          startDate: lastYear,
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/payment-methods",
        method: "get",
        params: {
          startDate: getYearTypeYYYY(),
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/statistics/payment-prices",
        method: "get",
        params: {
          startDate: getYearTypeYYYY(),
          periodSearchPatternType,
          storeId,
        },
      },
      {
        url: "/statistics/return-customer-count",
        method: "get",
        params: {
          startDate: getYearTypeYYYY(),
          periodSearchPatternType,
        },
      },
    ],
    {
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { yearInfo };
}
