import VerticalBarChart from "../chart/VerticalBarChart";
import LineChart from "../chart/LineChart";
import ContentBoard from "./ContentBoard";
import PieChart from "../chart/PieChart";
import HorizonBarChart from "../chart/HorizonBarChart";
import { useWeekly } from "../../hooks/dashboard/useWeekly";
import {
  getChartInfo,
  getCurrentWeekLabels,
  getRePurchaseData,
  getTotalSaleData,
} from "../common/dashboard";
import { getThisWeekReferenceDate } from "../common/date";
import CustomSpinner from "../common/CustomSpinner";

export default function Week() {
  const { weeklyInfo } = useWeekly({
    referenceDate: getThisWeekReferenceDate(),
    periodSearchPatternType: "WEEKLY",
    storeId: 1,
  });

  if (!weeklyInfo) {
    return <CustomSpinner />;
  }
  const [lastWeek, thisWeek, payment, totalSales, rePurchase] = weeklyInfo;
  const { summaries, osLabels, mapedPaymentContent, totalPaymentCount } =
    getChartInfo(lastWeek, thisWeek, payment);

  return (
    <div>
      <ContentBoard
        summaries={summaries}
        timeUnit="week"
        firstTitle="매출 그래프"
        firstChart={
          <LineChart
            isSales
            label="매출액"
            labels={getCurrentWeekLabels(totalSales)}
            datas={getTotalSaleData(totalSales)}
          />
        }
        secondTitle="재구매 추이"
        secondChart={
          <LineChart
            label="재구매 수"
            labels={getCurrentWeekLabels(rePurchase)}
            datas={getRePurchaseData(rePurchase)}
          />
        }
      />
      <ContentBoard
        isSummary
        firstTitle="회원 통계 - 사용 운영체제"
        firstChart={<PieChart {...osLabels} label="인원 수" />}
        secondTitle="결제 수단"
        secondChart={
          <HorizonBarChart
            labels={Array.from(
              { length: mapedPaymentContent.length },
              () => ""
            )}
            datas={mapedPaymentContent}
            paymentCount={totalPaymentCount}
            paymentInfo={payment.content}
          />
        }
        thirdTitle="회원 통계 - 성별, 연령대"
        thirdChart={
          <VerticalBarChart
            labels={[
              "0-12",
              "13-18",
              "19-24",
              "25-29",
              "30-34",
              "35-39",
              "40-44",
              "45-49",
              "50-54",
              "55-59",
              "60-",
            ]}
            firstData={[0, 0, 38, 10, 0, 0, 0, 0, 0, 0, 0]}
            firstLabel="여성"
            secondData={[0, 0, 10, 19, 0, 0, 0, 0, 0, 0, 0]}
            secondLabel="남성"
          />
        }
      />
    </div>
  );
}
