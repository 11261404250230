export const getTwoDigitTimeByOneDigitTime = (time) => {
  return ("00" + time).slice(-2);
};

export const getMonthDayLabel = (value = 0) => {
  const today = new Date();
  const baseDate = new Date(
    today.setDate(today.getDate() + 1 - today.getDay() + value)
  );

  return `${baseDate.getMonth() + 1}-${baseDate.getDate()}`;
};

export const getDayByDayNumber = (dayNumber) => {
  const restDayNumber = dayNumber % 7;
  const dayTexts = ["일", "월", "화", "수", "목", "금", "토"];

  return dayTexts[restDayNumber];
};

export const getThisWeek = () => {
  const today = new Date();
  const startOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay())
  );
  const endOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay() + 6)
  );

  const startYear = startOfWeek.getFullYear();
  const startMonth = getTwoDigitTimeByOneDigitTime(startOfWeek.getMonth() + 1);
  const startDate = getTwoDigitTimeByOneDigitTime(startOfWeek.getDate());
  const startDay = getDayByDayNumber(startOfWeek.getDay());

  const endYear = endOfWeek.getFullYear();
  const endMonth = getTwoDigitTimeByOneDigitTime(endOfWeek.getMonth() + 1);
  const endDate = getTwoDigitTimeByOneDigitTime(endOfWeek.getDate());
  const endDay = getDayByDayNumber(endOfWeek.getDay());

  return `${startYear}-${startMonth}-${startDate}(${startDay}) - ${endYear}-${endMonth}-${endDate}(${endDay})`;
};

export const getTodayTypeYYYYMMDD = () => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = getTwoDigitTimeByOneDigitTime(now.getMonth() + 1);
  const thisDate = getTwoDigitTimeByOneDigitTime(now.getDate());

  return `${thisYear}-${thisMonth}-${thisDate}`;
};

export const getTodayReferenceDate = () => {
  const now = new Date();

  const yesterDay = new Date(now.setDate(now.getDate() - 1));
  const yesterDayYear = yesterDay.getFullYear();
  const yesterDayMonth = getTwoDigitTimeByOneDigitTime(
    yesterDay.getMonth() + 1
  );
  const yesterDayDate = getTwoDigitTimeByOneDigitTime(yesterDay.getDate());

  return [
    getTodayTypeYYYYMMDD(),
    `${yesterDayYear}-${yesterDayMonth}-${yesterDayDate}`,
  ];
};

export const getThisWeekReferenceDate = () => {
  const today = new Date();
  const startOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay())
  );
  const endOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay() + 6)
  );
  const lastStartOfWeek = new Date(
    new Date().setDate(new Date().getDate() + 1 - new Date().getDay() - 7)
  );
  const lastEndOfWeek = new Date(
    new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6 - 7)
  );

  const startYear = startOfWeek.getFullYear();
  const startMonth = getTwoDigitTimeByOneDigitTime(startOfWeek.getMonth() + 1);
  const startDate = getTwoDigitTimeByOneDigitTime(startOfWeek.getDate());

  const endYear = endOfWeek.getFullYear();
  const endMonth = getTwoDigitTimeByOneDigitTime(endOfWeek.getMonth() + 1);
  const endDate = getTwoDigitTimeByOneDigitTime(endOfWeek.getDate());

  const lastStartYear = lastStartOfWeek.getFullYear();
  const lastStartMonth = getTwoDigitTimeByOneDigitTime(
    lastStartOfWeek.getMonth() + 1
  );
  const lastStartDate = getTwoDigitTimeByOneDigitTime(
    lastStartOfWeek.getDate()
  );

  const lastEndYear = lastEndOfWeek.getFullYear();
  const lastEndMonth = getTwoDigitTimeByOneDigitTime(
    lastEndOfWeek.getMonth() + 1
  );
  const lastEndDate = getTwoDigitTimeByOneDigitTime(lastEndOfWeek.getDate());

  return [
    {
      startDate: `${startYear}-${startMonth}-${startDate}`,
      endDate: `${endYear}-${endMonth}-${endDate}`,
    },
    {
      startDate: `${lastStartYear}-${lastStartMonth}-${lastStartDate}`,
      endDate: `${lastEndYear}-${lastEndMonth}-${lastEndDate}`,
    },
  ];
};

export const getMonthReferenceDate = () => {
  const today = new Date();

  const thisYear = today.getFullYear();
  const thistMonth = getTwoDigitTimeByOneDigitTime(today.getMonth() + 1);

  const lastYear = today.getFullYear();
  const lastMonth = getTwoDigitTimeByOneDigitTime(today.getMonth());

  return [`${thisYear}-${thistMonth}`, `${lastYear}-${lastMonth}`];
};

export const getMonthTypeYYYYMM = () => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = getTwoDigitTimeByOneDigitTime(now.getMonth() + 1);

  return `${thisYear}-${thisMonth}`;
};

export const getYearReferenceDate = () => {
  const today = new Date();

  const thisYear = today.getFullYear();

  const lastYear = today.getFullYear() - 1;

  return [`${thisYear}`, `${lastYear}`];
};

export const getYearTypeYYYY = () => {
  const now = new Date();
  const thisYear = now.getFullYear();

  return `${thisYear}`;
};
