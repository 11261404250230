import React from "react";

export function getApiUrl(api = "") {
  return process.env.REACT_APP_API_TEMP_TEMP + api;
}

export function getImgUrl(url) {
  return process.env.REACT_APP_IMGURL + url;
}

export function isAuthedUser() {
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    return true;
  } else {
    return false;
  }
}

export function PrintConsole(log) {
  if (process.env.NODE_ENV !== "production") {
    console.log(log);
  }
}

export function getSubDomain() {
  if (window.location.host.split(".")[0] === "admin") {
    return "admin";
  } else if (window.location.host.split(".")[0] === "store") {
    return "store";
  } else if (window.location.host.split(".")[0] === "user") {
    return "user";
  } else {
    return "localhost";
  }
}

export function setCommas(x) {
  if (isNaN(x)) {
    return "";
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function getNewlines(html) {
  if (!html) return null;

  const lines = html.split("\\n");

  if (lines.length === 0) {
    return html;
  }

  return lines.map((line, index) =>
    React.createElement(
      "span",
      {
        key: index,
      },
      line,
      React.createElement("br")
    )
  );
}

export function includeStr(str, check) {
  if (str.includes(check)) {
    return true;
  } else {
    return false;
  }
}

export function makeAddress(item) {
  if (!item) {
    return;
  }

  var name = item.name,
    region = item.region,
    land = item.land,
    isRoadAddress = name === "roadaddr";

  var sido = "",
    sigugun = "",
    dongmyun = "",
    ri = "",
    rest = "";

  if (hasArea(region.area1)) {
    sido = region.area1.name;
  }

  if (hasArea(region.area2)) {
    sigugun = region.area2.name;
  }

  if (hasArea(region.area3)) {
    dongmyun = region.area3.name;
  }

  if (hasArea(region.area4)) {
    ri = region.area4.name;
  }

  if (land) {
    if (hasData(land.number1)) {
      if (hasData(land.type) && land.type === "2") {
        rest += "산";
      }

      rest += land.number1;

      if (hasData(land.number2)) {
        rest += "-" + land.number2;
      }
    }

    if (isRoadAddress === true) {
      if (checkLastString(dongmyun, "면")) {
        ri = land.name;
      } else {
        dongmyun = land.name;
        ri = "";
      }

      if (hasAddition(land.addition0)) {
        rest += " " + land.addition0.value;
      }
    }
  }

  return [sido, sigugun, dongmyun, ri, rest].join(" ");
}

export function hasArea(area) {
  return !!(area && area.name && area.name !== "");
}

export function hasData(data) {
  return !!(data && data !== "");
}

export function checkLastString(word, lastString) {
  return new RegExp(lastString + "$").test(word);
}

export function hasAddition(addition) {
  return !!(addition && addition.value);
}

export function maxLengthCheck(object) {
  if (object.value.Length > object.max.Length)
    object.value = object.value.slice(0, object.max.Length);
}

export function EmailCheck(text) {
  var regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  if (text.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
}
export function EngNumCheck(text) {
  console.log(text);
  var regExp = /^[A-Za-z0-9]+$/;
  if (text.match(regExp) != null) {
    return false;
  } else {
    return true;
  }
}
