import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function SettlementBoard({ settlements, setCheckSettlements }) {
  const mapedSettlementInfos = settlements.map((settlementInfo) => {
    const {
      isChecked,
      id,
      settleStatus,
      year,
      weekOfYear,
      startDate,
      endDate,
      storeName,
      settledAt,
      scheduledSettledAt,
      priceInfo,
      settleAgentStatus,
    } = settlementInfo;

    return (
      <tr key={id} className="board-row-except">
        <td style={{ paddingLeft: "30px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={settleStatus.code === 2}
                  onClick={() => {
                    setCheckSettlements((prev) => {
                      return prev.map((item) => {
                        if (item.id === id) {
                          return {
                            ...item,
                            isChecked: !item.isChecked,
                          };
                        }

                        return item;
                      });
                    });
                  }}
                />
              }
              label="선택"
            />
          </FormGroup>
        </td>
        <td>{id || "-"}</td>
        <td>{storeName || "-"}</td>
        <td>{year || "-"}</td>
        <td>{weekOfYear || "-"}</td>
        <td>{startDate || "-"}</td>
        <td>{endDate || "-"}</td>
        <td>{settledAt || "-"}</td>
        <td>{priceInfo?.storeSalesPrice.toLocaleString() || "-"}</td>
        <td>{priceInfo?.storeSettlePrice.toLocaleString() || "-"}</td>
        <td>{priceInfo?.fee.toLocaleString() || "-"}</td>
        <td>{priceInfo?.countOfPayment || "-"}</td>
        <td>{scheduledSettledAt || "-"}</td>
        <td>{settleStatus ? settleStatus.description : "-"}</td>
        <td>{settleAgentStatus ? settleAgentStatus.description : "-"}</td>
      </tr>
    );
  });

  return mapedSettlementInfos;
}
