import "./MidBoard.css";

export default function MidBoard({
  firstTitle,
  firstChart,
  secondTitle,
  secondChart,
  isMid,
}) {
  if (isMid) {
    return (
      <div className="midBoard-container">
        <div className="midBoard-full-content">
          <h3 className="midBoard-title">{firstTitle}</h3>
          <div className="midBoard-chart-container">{firstChart}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="midBoard-container">
      <div className="midBoard-content">
        <h3 className="midBoard-title">{firstTitle}</h3>
        <div className="midBoard-chart-container">{firstChart}</div>
      </div>
      <div className="midBoard-content">
        <h3 className="midBoard-title">{secondTitle}</h3>
        <div className="midBoard-chart-container">{secondChart}</div>
      </div>
    </div>
  );
}
