import { useState } from "react";
import { Table } from "react-bootstrap";
import { useCouponDetailLogs } from "../../hooks/coupon/useCouponDetailLogs";
import CustomSpinner from "../common/CustomSpinner";
import SimplePagination from "../common/SimplePagination";

export default function CouponDetailBoard() {
  const [page, setPage] = useState(0);
  const { couponLogs } = useCouponDetailLogs({ page });

  if (!couponLogs) {
    return <CustomSpinner />;
  }

  const mapedCouponLogs = couponLogs.content.map((couponLog) => {
    const {
      id,
      couponLogType,
      createdAt,
      status,
      usedAt,
      buyerName,
      ownerName,
      nonUserName,
    } = couponLog;

    return (
      <tr key={id}>
        <td>{buyerName || "-"}</td>
        <td>{ownerName || nonUserName || "-"}</td>
        <td>{couponLogType ? couponLogType.description : "-"}</td>
        <td>{usedAt || "-"}</td>
        <td>{createdAt || "-"}</td>
      </tr>
    );
  });

  return (
    <div className="TableStyle mt-4">
      <h3 style={{ fontSize: "15px", fontWeight: 700 }}>교환권 변경내역</h3>
      <Table style={{ verticalAlign: "middle" }} className="mb-4 text-center">
        <thead>
          <tr>
            <th>구매자명</th>
            <th>소유자명</th>
            <th>상태</th>
            <th>사용일시</th>
            <th>내역생성일시</th>
          </tr>
        </thead>
        <tbody>{mapedCouponLogs}</tbody>
      </Table>
      <SimplePagination
        currentPage={page}
        totalPage={couponLogs.totalPages}
        setPage={setPage}
      />
    </div>
  );
}
