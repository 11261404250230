export const initialState = {
  storeName: "",
};

export const settlementReducer = (state, action) => {
  switch (action.type) {
    case "changed_storeName":
      return { ...state, storeName: action.payload };
    case "reseted":
      return { ...initialState };
    case "moved":
      return { ...action.payload };
    default:
      throw Error(`Unknown action type: ${action.type}`);
  }
};
