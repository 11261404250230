import { Spinner } from "react-bootstrap";
import LoadingPortal from "../../portals/LoadingPortal";

export default function CustomSpinner({ type = "" }) {
  if (type === "backdrop") {
    return (
      <LoadingPortal>
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0.5,
            backgroundColor: "black",
          }}
        ></div>
        <Spinner
          animation="border"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-18px",
          }}
        />
      </LoadingPortal>
    );
  }

  return (
    <LoadingPortal>
      <Spinner
        animation="border"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      />
    </LoadingPortal>
  );
}
