import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function LineChart({ isSales, labels, datas, label }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "end",
        font: { size: 14 },
        formatter: function (value, context) {
          if (isSales) {
            return `${value.toLocaleString()}원`;
          }

          return value;
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 50,
        left: 50,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label,
        data: datas,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <Line
      width={500}
      height={200}
      options={options}
      data={data}
      plugins={[ChartDataLabels]}
    />
  );
}
