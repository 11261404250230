const getSummaryValues = (thisValue, lastValue) => {
  let percentage = Math.round(((thisValue - lastValue) / lastValue) * 100);

  if (percentage === Infinity) {
    percentage = Math.round((thisValue - lastValue) / 1);

    return {
      count: thisValue,
      percentage,
    };
  }

  if (isNaN(percentage)) {
    return {
      count: thisValue,
      percentage: 0,
    };
  }

  return {
    count: thisValue,
    percentage,
  };
};

const getOSValues = (osIosCount, osAosCount, osEtcCount) => {
  const totalOSCount = osIosCount + osAosCount + osEtcCount;
  const iosPercentage = isNaN(osIosCount / totalOSCount)
    ? 0
    : osIosCount / totalOSCount;
  const aosPercentage = isNaN(osAosCount / totalOSCount)
    ? 0
    : osAosCount / totalOSCount;
  const etcPercentage = isNaN(osEtcCount / totalOSCount)
    ? 0
    : osEtcCount / totalOSCount;

  return {
    labels: [
      `IOS ${((Math.round(iosPercentage * 100) / 100) * 100).toFixed(0)}%`,
      `AOS ${((Math.round(aosPercentage * 100) / 100) * 100).toFixed(0)}%`,
      `Etc. ${((Math.round(etcPercentage * 100) / 100) * 100).toFixed(0)}%`,
    ],
    datas: [osIosCount, osAosCount, osEtcCount],
  };
};

export const getPaymetInfo = (payment) => {
  const mapedPaymentContent = payment.content.map(({ count }) => count);
  const totalPaymentCount = payment.content.reduce(
    (acc, prev) => acc + prev.count,
    0
  );

  return {
    mapedPaymentContent,
    totalPaymentCount,
  };
};

export const getChartInfo = (lastPeriod, thisPeriod, payment) => {
  const {
    paymentPrice,
    netIncome,
    orderCount,
    newSignup,
    osIosCount,
    osAosCount,
    osEtcCount,
  } = thisPeriod;

  const {
    paymentPrice: lPaymentPrice,
    netIncome: lNetIncome,
    orderCount: lOrderCount,
    newSignup: lNewSignup,
  } = lastPeriod;

  const summaries = [
    getSummaryValues(paymentPrice, lPaymentPrice),
    getSummaryValues(netIncome, lNetIncome),
    getSummaryValues(orderCount, lOrderCount),
    getSummaryValues(newSignup, lNewSignup),
  ];

  const osLabels = getOSValues(osIosCount, osAosCount, osEtcCount);

  return {
    summaries,
    osLabels,
    ...getPaymetInfo(payment),
  };
};

export const getCurrentWeekLabels = (labelTexts) => {
  const days = ["월", "화", "수", "목", "금", "토", "일"];

  const mapedTexts = labelTexts.map(({ referenceDate }, index) => {
    const [year, month, date] = referenceDate.split("-");

    return [`${month}-${date}`, `(${days[index]})`];
  });

  return [...mapedTexts];
};

export const getCurrentSalesMonthLabels = (labelTexts) => {
  const mapedTexts = labelTexts.map(({ monthOfweekNumber }) => {
    const now = new Date();
    const thisMonth = now.getMonth() + 1;

    return `${thisMonth}월 ${monthOfweekNumber}주차`;
  });

  return mapedTexts;
};

export const getCurrentRePurchaseMonthLabels = (labelTexts) => {
  const mapedTexts = labelTexts.map(({ referenceDate }) => {
    const now = new Date();
    const thisMonth = now.getMonth() + 1;

    return `${thisMonth}월 ${referenceDate}주차`;
  });

  return mapedTexts;
};

export const getCurrentYearLabels = (labelTexts) => {
  const mapedTexts = labelTexts.map(({ referenceDate }) => {
    return `${referenceDate}월`;
  });

  return mapedTexts;
};

export const getRePurchaseData = (data) => {
  return data.map(({ count }) => count);
};

export const getTotalSaleData = (data) => {
  return data.map(({ paymentPrice }) => paymentPrice);
};
