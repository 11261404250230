import { apiClient } from "../../api/apiClient";
import { getParamsDate, getParamsDateAndTime } from "../../function/date";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function usePayments({ page, searchState }) {
  const {
    storeName,
    buyerName,
    orderNumber,
    startDate,
    startTime,
    endDate,
    endTime,
    status,
    phoneNumber,
    userId,
  } = searchState;
  const newParams = Object.assign(
    {},
    storeName !== "" && { storeName },
    buyerName !== "" && { buyerName },
    orderNumber !== "" && { orderNumber },
    status !== "" && { paymentStatus: status },
    phoneNumber !== "" && { phoneNumber },
    userId !== "" && { userId }
  );

  const { data: payments, isLoading } = useFetch(
    [
      String(page),
      storeName,
      buyerName,
      orderNumber,
      getParamsDate(startDate),
      startTime,
      getParamsDate(endDate),
      endTime,
      status,
      phoneNumber,
      userId,
    ],
    async () => {
      const { data } = await apiClient({
        url: "/payments",
        method: "get",
        params: {
          page,
          ...newParams,
          startTime: getParamsDateAndTime(startDate, startTime),
          endTime: getParamsDateAndTime(endDate, endTime),
          size: POST_SIZE,
        },
      });

      return data;
    }
  );

  return { payments, isLoading };
}
