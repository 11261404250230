import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useCompletedSettlement({ refetch, setIsAllChecked }) {
  const { mutate, isLoading } = useMutation(
    async ({ settleIds }) => {
      return await apiClient({
        url: "/settlement/complete",
        method: "post",
        data: {
          settleIds,
        },
      });
    },
    {
      onSuccess: () => {
        setIsAllChecked(false);
        refetch();
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { mutate, isLoading };
}
