import { useNavigate } from "react-router-dom";

export default function UserBoard({ users }) {
  const navigate = useNavigate();

  const mapedUsers = users.content.map((user) => {
    const {
      createdAt,
      email,
      id,
      name,
      oauthType,
      phoneNumber,
      userStatus,
      deviceInfo,
    } = user;

    return (
      <tr
        className="board-row"
        key={id}
        onClick={() => navigate(`/admin/user/users/${id}`)}
      >
        <td>{id}</td>
        <td>{name ? name : "-"}</td>
        <td>{email || "-"}</td>
        <td>{phoneNumber || "-"}</td>
        <td>{deviceInfo || "-"}</td>
        <td>{userStatus ? userStatus.description : "-"}</td>
        <td>{oauthType || "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
      </tr>
    );
  });

  return mapedUsers;
}
