import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useCompletedSettlementProcessing({ refetch, setIsAllChecked }) {
  const { mutate: completedProcessMutate, isCompletedProcessLoading } =
    useMutation(
      async ({ settleIds }) => {
        return await apiClient({
          url: "/settlements/agent/complete",
          method: "post",
          data: {
            settleIds: settleIds,
          },
        });
      },
      {
        onSuccess: () => {
          setIsAllChecked(false);
          refetch();
        },
        onError: ({ response }) => {
          console.log(response);
          const { data } = response;
          const { message, errorDetails } = data;

          alert(`${message}\n${errorDetails[0].reason}`);
        },
      }
    );

  return { completedProcessMutate, isCompletedProcessLoading };
}
