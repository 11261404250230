import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js";
import { Container } from "react-bootstrap";
import "./DashBoard.css";
import LeftMenu from "../../../components/admin/LeftMenu";

import { useState, useMemo } from "react";
import Today from "../../../components/dashboard/Today";
import Week from "../../../components/dashboard/Week";
import Month from "../../../components/dashboard/Month";
import Year from "../../../components/dashboard/Year";

const MOCK = ["오늘", "이번주", "이번달", "올해"];

const TAB_STATUS = {
  TODAY: "오늘",
  WEEK: "이번주",
  MONTH: "이번달",
  YEAR: "올해",
};

Chart.register(
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);

export default function DashBoard() {
  const [tabs, setTabs] = useState(TAB_STATUS.TODAY);

  const handleTabClick = (text) => {
    setTabs(text);
  };

  const mapedMocks = useMemo(
    () =>
      MOCK.map((text) => (
        <li
          key={text}
          className={`dashBoard-tab__item ${
            tabs === text && "dashBoard-tab__item--active"
          }`}
          onClick={() => handleTabClick(text)}
        >
          {text}
        </li>
      )),
    [tabs]
  );

  return (
    <>
      <Container fluid className="px-0">
        <div className="dashBoard-wrapper">
          <div className="LeftBg px-0">
            <LeftMenu />
          </div>
          <div className="dashBoard-content-wrapper px-5">
            <h1 className="fw-bold">대시보드</h1>
            <ul className="dashBoard-tab__list">{mapedMocks}</ul>
            <div className="dashBoard-tab-content__container">
              {tabs === TAB_STATUS.TODAY && <Today />}
              {tabs === TAB_STATUS.WEEK && <Week />}
              {tabs === TAB_STATUS.MONTH && <Month />}
              {tabs === TAB_STATUS.YEAR && <Year />}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
