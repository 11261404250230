import { apiClient } from "../../api/apiClient";
import { useMutation } from "./useMutation";

const getExtension = (logoUrl) => {
  return logoUrl.split(".").pop();
};

const downloadLogo = (domain, logoUrl, res) => {
  const blob = new Blob([res.data]);
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = fileUrl;
  link.style.display = "none";
  link.download = domain + " logo." + getExtension(logoUrl);

  document.body.appendChild(link);

  link.click();
  link.remove();

  window.URL.revokeObjectURL(fileUrl);
};

export function useLogoDownload() {
  const { mutate: mutateLogo, isLoading } = useMutation(
    async ({ domain, logoUrl }) => {
      if (!logoUrl) {
        alert("업로드된 로고가 없습니다.");
        return;
      }

      const res = await apiClient({
        url: logoUrl + "?wow",
        method: "get",
        responseType: "blob",
      });

      return { domain, logoUrl, res };
    },
    {
      onSuccess: ({ domain, logoUrl, res }) => {
        downloadLogo(domain, logoUrl, res);
      },
    }
  );

  return { mutateLogo, isLoading };
}
