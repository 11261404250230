import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import { useStoreDetail } from "../../../hooks/stores/useStoreDetail";

export default function StoreDetail({ storeRequestRef }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { store } = useStoreDetail({ id });

  if (!store) {
    return <CustomSpinner />;
  }

  const {
    storeId,
    storeName,
    domain,
    status,
    businessHours,
    logoUrl,
    location,
    createdAt,
    account,
    businessNumber,
    businessName,
    ownerName,
    storeAddress,
    taxEmail,
  } = store;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 상세정보</h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <div style={{ width: "100%" }}>
                <div
                  className="pb-4 btn-py"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    뒤로가기
                  </button>
                </div>
              </div>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      가맹점번호 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점 번호를 입력하세요."
                      value={storeId}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      가맹점명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점명을 입력하세요."
                      value={storeName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      도메인 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="도메인을 입력하세요."
                      value={domain}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      운영 상태 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="운영 상태를 입력하세요."
                      value={status ? status.description : "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      운영 시간 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가입방식을 입력하세요."
                      value={businessHours}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      장소 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="장소를 입력하세요."
                      value={location}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      정산은행명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산은행명를 입력하세요."
                      value={
                        account.settleBankCode
                          ? account.settleBankCode.korName
                          : "-"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      정산계좌 이메일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산계좌 이메일를 입력하세요."
                      value={account.settleAccountNumber || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      정산계좌 소유명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산계좌 소유명를 입력하세요."
                      value={account.settleAccountOwner || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      사업번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업번호를 입력하세요."
                      value={businessNumber || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      사업장명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업장명를 입력하세요."
                      value={businessName || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      사업자명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업자명를 입력하세요."
                      value={ownerName || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      사업장주소 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업장주소를 입력하세요."
                      value={storeAddress || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      세금메일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="세금메일을 입력하세요."
                      value={taxEmail || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      생성일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="생성일을 입력하세요."
                      value={
                        createdAt
                          ? createdAt.replace("T", " ").substring(0, 16)
                          : "-"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
