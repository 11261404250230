import { apiClient } from "../../api/apiClient";
import { getParamsDate, getParamsDateAndTime } from "../../function/date";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useCoupons({ page, searchState, isUsedStatus }) {
  const {
    storeName,
    ownerName,
    buyerName,
    status,
    startTimeOfCreatedAt,
    endTimeOfCreatedAt,
    createdStartTime,
    createdEndTime,
    startTimeOfUsedAt,
    endTimeOfUsedAt,
    usedStartTime,
    usedEndTime,
  } = searchState;

  const params = Object.assign(
    {
      page,
      size: POST_SIZE,
      startTimeOfCreatedAt: getParamsDateAndTime(
        startTimeOfCreatedAt,
        createdStartTime
      ),
      endTimeOfCreatedAt: getParamsDateAndTime(
        endTimeOfCreatedAt,
        createdEndTime
      ),
    },
    storeName !== "" && { storeName },
    ownerName !== "" && { ownerName },
    buyerName !== "" && { buyerName },
    status !== "" && { couponStatus: status },

    isUsedStatus &&
      startTimeOfUsedAt !== null && {
        startTimeOfUsedAt: getParamsDateAndTime(
          startTimeOfUsedAt,
          usedStartTime
        ),
      },
    isUsedStatus &&
      endTimeOfUsedAt !== null && {
        endTimeOfUsedAt: getParamsDateAndTime(endTimeOfUsedAt, usedEndTime),
      }
  );

  const {
    data: coupons,
    isLoading,
    refetch,
  } = useFetch(
    [
      String(page),
      storeName,
      ownerName,
      buyerName,
      status,
      getParamsDate(startTimeOfCreatedAt),
      getParamsDate(endTimeOfCreatedAt),
      getParamsDate(startTimeOfUsedAt),
      getParamsDate(endTimeOfUsedAt),
      createdStartTime,
      createdEndTime,
      usedStartTime,
      usedEndTime,
    ],
    async () => {
      const { data } = await apiClient({
        url: "/coupons",
        method: "get",
        params,
      });

      return data;
    }
  );

  return { coupons, isLoading, refetch };
}
