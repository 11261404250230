import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

export function useCouponDetail({ couponId }) {
  const { data: coupon } = useFetch([], async () => {
    const { data } = await apiClient({
      url: `/coupons/${couponId}`,
      method: "get",
    });

    return data;
  });

  return { coupon };
}
