import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import AdminLogin from "./pages/admin/main/AdminLogin";
import DashBoard from "./pages/admin/main/DashBoard";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/common/PrivateRoute";
import { Stack } from "react-bootstrap";
import Payment from "./pages/admin/payment/Payment";
import User from "./pages/admin/user/User";
import Coupon from "./pages/admin/coupon/Coupon";
import SignUp from "./pages/admin/signup/SignUp";
import Settlement from "./pages/admin/settlement/Settlement";
import NonUser from "./pages/admin/user/NonUser";
import UserDetail from "./pages/admin/user/UserDetail";
import { useRef } from "react";
import Stores from "./pages/admin/stores/Stores";
import StoreDetail from "./pages/admin/stores/StoreDetail";
import CouponDetail from "./pages/admin/coupon/CouponDetail";
import PaymentDetail from "./pages/admin/payment/PaymentDetail";
import Admin from "./pages/admin/admin/Admin";
import Advertisement from "./pages/admin/advertisement/Advertisement";

function App() {
  const storeRequestRef = useRef(false);
  const adRequestRef = useRef(false);

  function logout() {
    localStorage.clear();
    window.location.href = "/admin/login";
  }

  const Header = () => {
    if (window.location.pathname === "/admin/login") return null;
    return (
      <header className="App-header pt-4 px-5 ">
        <Stack direction="horizontal" gap={3}>
          <div
            onClick={logout}
            className="border border-0 ms-auto px-3 text-white"
            style={{
              height: "30px",
              lineHeight: "30px",
              borderRadius: "50px",
              background: "#333333",
              cursor: "pointer",
            }}
          >
            로그 아웃
          </div>
        </Stack>
      </header>
    );
  };

  return (
    <div className="App">
      <Header />
      <main>
        <Router>
          <Routes>
            <Route
              path="/admin/home"
              element={
                <PrivateRoute>
                  <DashBoard />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<Navigate to="/admin/home" />} />
            <Route
              path="/admin/admins/users"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/payment"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/payment/:orderNumber"
              element={
                <PrivateRoute>
                  <PaymentDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/sale/settlement"
              element={
                <PrivateRoute>
                  <Settlement />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/user/users"
              element={
                <PrivateRoute>
                  <User />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/user/users/:id"
              element={
                <PrivateRoute>
                  <UserDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/user/non-users"
              element={
                <PrivateRoute>
                  <NonUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/coupon/coupons"
              element={
                <PrivateRoute>
                  <Coupon />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/coupon/coupons/:couponId"
              element={
                <PrivateRoute>
                  <CouponDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/store/stores"
              element={
                <PrivateRoute>
                  <Stores storeRequestRef={storeRequestRef} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/store/stores/:id"
              element={
                <PrivateRoute>
                  <StoreDetail storeRequestRef={storeRequestRef} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/advertisements/list"
              element={
                <PrivateRoute>
                  <Advertisement adRequestRef={adRequestRef} />
                </PrivateRoute>
              }
            />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/signup" element={<SignUp />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </main>
    </div>
  );
}

export default App;
