import { Link } from "react-router-dom";
import { Container, Form, Image, Row, Col } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import Switch from "../../../components/admin/Swtich";
import { useState } from "react";
import { useLogin } from "../../../hooks/login/useLogin";

export default function AdminLogin() {
  const [auth, setAuth] = useState({
    loginId: "",
    password: "",
  });
  const { mutate, isLoading } = useLogin();

  const handleAuthChange = ({ target }) => {
    const { id, value } = target;

    setAuth((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleLoginBtnClick = () => {
    if (isLoading) {
      return;
    }

    mutate(auth);
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    handleLoginBtnClick();
  };

  return (
    <>
      <Container
        fluid
        className="d-flex align-items-center g-0"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="mx-auto p-3"
          style={{ width: "90%", maxWidth: "320px" }}
        >
          <Row style={{ margin: "auto" }} className="g-0">
            <div style={{ width: "100%", textAlign: "center" }}>
              <Image
                style={{
                  width: "100%",
                  maxWidth: "100px",
                  marginBottom: "4rem",
                }}
                src={
                  "https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png"
                }
              ></Image>
            </div>
          </Row>

          <Row className="justify-content-end mb-3">
            <Col xs={"auto"}>
              <Link to="/admin/" style={{ fontSize: "13px", color: "#666666" }}>
                아이디 찾기
              </Link>
            </Col>
            <Col xs={"auto"} className="px-0">
              <div
                className="h-75 mt-1"
                style={{ width: "1px", background: "#9b9b9b" }}
              ></div>
            </Col>
            <Col xs={"auto"}>
              <Link to="/admin/" style={{ fontSize: "13px", color: "#666666" }}>
                비밀번호 찾기
              </Link>
            </Col>
            <Col xs={"auto"} className="px-0">
              <div
                className="h-75 mt-1"
                style={{ width: "1px", background: "#9b9b9b" }}
              ></div>
            </Col>
            <Col xs={"auto"}>
              <Link
                to="/admin/signup/"
                style={{ fontSize: "13px", color: "#666666" }}
              >
                회원가입
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="loginBox">
                <Form.Control
                  id="loginId"
                  type="text"
                  className="inputType mb-3"
                  placeholder="아이디를 입력해주세요."
                  value={auth.id}
                  onChange={handleAuthChange}
                  onKeyDown={handleInputKeyDown}
                />

                <Form.Control
                  id="password"
                  type="password"
                  className="inputType mb-3"
                  placeholder="비밀번호를 입력해주세요."
                  value={auth.password}
                  onChange={handleAuthChange}
                  onKeyDown={handleInputKeyDown}
                />

                <div className="loginPrimary mb-2">
                  <Btn
                    type="API"
                    btntext="로그인"
                    onClick={handleLoginBtnClick}
                  />
                </div>

                <Switch label="자동 로그인" />
              </div>
            </Col>
          </Row>

          <Row className="mt-5 mb-3 text-center">
            <Col>
              <Image
                className="img-fluid mb-2"
                style={{ width: "40%" }}
                src={
                  "https://www.flexdaycdn.net/public/images/moneyshow/flexday_logo.png"
                }
              ></Image>

              <p
                className="mb-0"
                style={{ fontSize: "13px", color: "#666666" }}
              >
                Copyright 2022. Flexday rights reserved.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={"auto"}>
              <Link to="/" style={{ fontSize: "13px", color: "#666666" }}>
                이용약관
              </Link>
            </Col>
            <Col xs={"auto"} className="px-0">
              <div
                className="h-75 mt-1"
                style={{ width: "1px", background: "#9b9b9b" }}
              ></div>
            </Col>
            <Col xs={"auto"}>
              <Link to="/" style={{ fontSize: "13px", color: "#666666" }}>
                개인정보처리방침
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
