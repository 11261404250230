import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

const POST_SIZE = 10;

export function useAdmins({ page }) {
  const { data: admins, refetch } = useFetch([String(page)], async () => {
    const { data } = await apiClient({
      url: `/admins`,
      params: {
        page,
        size: POST_SIZE,
      },
    });

    return data;
  });

  return { admins, refetch };
}
