import "./BottomBoard.css";

export default function BottomBoard({
  secondTitle,
  secondChart,
  thirdTitle,
  thirdChart,
  isMid,
  isRePurchase,
}) {
  if (isMid) {
    return (
      <div className="bottomBoard-container">
        <div className="bottomBoard-content">
          <h3 className="bottomBoard-title">{secondTitle}</h3>
          {!isRePurchase && (
            <div className="bottomBoard-legend">
              <ul className="bottomBoard-legend-list">
                <li className="bottomBoard-legend-item">
                  <div className="bottomBoard-legend--woman"></div>여성
                </li>
                <li className="bottomBoard-legend-item">
                  <div className="bottomBoard-legend--man"></div>남성
                </li>
              </ul>
            </div>
          )}
          <div className="bottomBoard-chart-container">{secondChart}</div>
        </div>
      </div>
    );
  }

  if (!thirdTitle) {
    return;
  }

  return (
    <div className="bottomBoard-container">
      <div className="bottomBoard-content">
        <h3 className="bottomBoard-title">{thirdTitle}</h3>
        <div className="bottomBoard-legend">
          <ul className="bottomBoard-legend-list">
            <li className="bottomBoard-legend-item">
              <div className="bottomBoard-legend--woman"></div>여성
            </li>
            <li className="bottomBoard-legend-item">
              <div className="bottomBoard-legend--man"></div>남성
            </li>
          </ul>
        </div>
        <div className="bottomBoard-chart-container">{thirdChart}</div>
      </div>
    </div>
  );
}
