import { Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import { useCallback, useEffect, useReducer } from "react";
import PaymentBoard from "../../../components/payment/PaymentBoard";
import {
  initialState,
  paymentReducer,
} from "../../../reducers/payment/paymentReducer";
import { useLocation, useNavigate } from "react-router-dom";
import DetailPagination from "../../../components/common/DetailPagination";
import { useState } from "react";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import { usePayments } from "../../../hooks/payment/usePayments";
import CustomSpinner from "../../../components/common/CustomSpinner";
import { transformYYYYMMDD } from "../../../function/date";

export default function Payment() {
  const location = useLocation();
  const {
    pageQuery,
    storeNameQuery,
    buyerNameQuery,
    orderNumberQuery,
    startDateQuery,
    endDateQuery,
    startTimeQuery,
    endTimeQuery,
    statusQuery,
    phoneNumberQuery,
    userIdQuery,
  } = getQueryStringObj();
  const navigate = useNavigate();
  const [page, setPage] = useState(pageQuery || 0);
  const [showingState, showingDispatch] = useReducer(paymentReducer, {
    ...initialState,
    storeName: storeNameQuery || initialState.storeName,
    buyerName: buyerNameQuery || initialState.buyerName,
    orderNumber: orderNumberQuery || initialState.orderNumber,
    startDate: startDateQuery || initialState.startDate,
    endDate: endDateQuery || initialState.endDate,
    startTime: startTimeQuery || initialState.startTime,
    endTime: endTimeQuery || initialState.endTime,
    status: statusQuery || initialState.status,
    phoneNumber: phoneNumberQuery || initialState.phoneNumber,
    userId: userIdQuery || initialState.userId,
  });
  const [searchState, searchDispatch] = useReducer(paymentReducer, {
    ...initialState,
    storeName: storeNameQuery || initialState.storeName,
    buyerName: buyerNameQuery || initialState.buyerName,
    orderNumber: orderNumberQuery || initialState.orderNumber,
    startDate: startDateQuery || initialState.startDate,
    endDate: endDateQuery || initialState.endDate,
    startTime: startTimeQuery || initialState.startTime,
    endTime: endTimeQuery || initialState.endTime,
    status: statusQuery || initialState.status,
    phoneNumber: phoneNumberQuery || initialState.phoneNumber,
    userId: userIdQuery || initialState.userId,
  });
  const { payments, isLoading } = usePayments({
    page,
    searchState,
  });

  function getQueryString() {
    return location.search
      .replace(
        /\?page=|&storeName=|&buyerName=|&orderNumber=|&startDate=|&endDate=|&startTime=|&endTime=|&status=|&phoneNumber=|&userId=/g,
        " "
      )
      .split(" ");
  }

  function getQueryStringObj() {
    const [
      _,
      pageQuery,
      storeNameQuery,
      buyerNameQuery,
      orderNumberQuery,
      startDateQuery,
      endDateQuery,
      startTimeQuery,
      endTimeQuery,
      statusQuery,
      phoneNumberQuery,
      userIdQuery,
    ] = getQueryString();

    if (
      pageQuery === undefined ||
      storeNameQuery === undefined ||
      buyerNameQuery === undefined ||
      orderNumberQuery === undefined ||
      phoneNumberQuery === undefined ||
      statusQuery === undefined ||
      startDateQuery === undefined ||
      endDateQuery === undefined ||
      startTimeQuery === undefined ||
      endTimeQuery === undefined ||
      userIdQuery === undefined
    ) {
      return {
        pageQuery: "",
        storeNameQuery: "",
        buyerNameQuery: "",
        orderNumberQuery: "",
        phoneNumberQuery: "",
        statusQuery: "",
        startDateQuery: new Date(),
        endDateQuery: new Date(),
        startTimeQuery: "0",
        endTimeQuery: "23",
        userIdQuery: "",
      };
    }

    return {
      pageQuery: decodeURI(pageQuery),
      storeNameQuery: decodeURI(storeNameQuery),
      buyerNameQuery: decodeURI(buyerNameQuery),
      orderNumberQuery: decodeURI(orderNumberQuery),
      phoneNumberQuery: decodeURI(phoneNumberQuery),
      statusQuery: decodeURI(statusQuery),
      startDateQuery: new Date(decodeURI(startDateQuery)),
      endDateQuery: new Date(decodeURI(endDateQuery)),
      startTimeQuery: decodeURI(startTimeQuery),
      endTimeQuery: decodeURI(endTimeQuery),
      userIdQuery: decodeURI(userIdQuery),
    };
  }

  const setPageStatus = useCallback(() => {
    const [
      _,
      page,
      storeName,
      buyerName,
      orderNumber,
      startDate,
      endDate,
      startTime,
      endTime,
      status,
      phoneNumber,
      userId,
    ] = getQueryString();

    if (!page) {
      setPage(0);
      showingDispatch({ type: "reseted" });
      searchDispatch({ type: "reseted" });
      return;
    }

    setPage(page);
    showingDispatch({
      type: "moved",
      payload: {
        storeName: decodeURI(storeName),
        buyerName: decodeURI(buyerName),
        orderNumber: decodeURI(orderNumber),
        startDate: new Date(decodeURI(startDate)),
        startTime: decodeURI(startTime),
        endDate: new Date(decodeURI(endDate)),
        endTime: decodeURI(endTime),
        status: decodeURI(status),
        phoneNumber: decodeURI(phoneNumber),
        userId: decodeURI(userId),
      },
    });
    searchDispatch({
      type: "moved",
      payload: {
        storeName: decodeURI(storeName),
        buyerName: decodeURI(buyerName),
        orderNumber: decodeURI(orderNumber),
        startDate: new Date(decodeURI(startDate)),
        startTime: decodeURI(startTime),
        endDate: new Date(decodeURI(endDate)),
        endTime: decodeURI(endTime),
        status: decodeURI(status),
        phoneNumber: decodeURI(phoneNumber),
        userId: decodeURI(userId),
      },
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  if (!payments) {
    return <CustomSpinner />;
  }

  const getNavigateStr = (state, page = 0) => {
    const {
      storeName,
      buyerName,
      orderNumber,
      startDate,
      endDate,
      startTime,
      endTime,
      status,
      phoneNumber,
      userId,
    } = state;

    return `?page=${page}&storeName=${storeName}&buyerName=${buyerName}&orderNumber=${orderNumber}&startDate=${transformYYYYMMDD(
      startDate
    )}&endDate=${transformYYYYMMDD(
      endDate
    )}&startTime=${startTime}&endTime=${endTime}&status=${status}&phoneNumber=${phoneNumber}&userId=${userId}`;
  };

  const searchPaymentInfo = () => {
    if (isLoading) {
      return;
    }

    searchDispatch({ type: "moved", payload: showingState });
    navigate(getNavigateStr(showingState));
  };

  const handleSearchBtnClick = () => {
    searchPaymentInfo();
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    searchPaymentInfo();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">결제 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{payments.totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점 이름</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.storeName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_storeName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">구매자명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.buyerName}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_buyerName",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">주문번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="orderNumber"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.orderNumber}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_orderNumber",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.userId}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_userId",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">전화번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.phoneNumber}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_phoneNumber",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원 상태</h5>
                      </Col>
                      <Col>
                        <Form className="form-radio">
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                                checked={showingState.status === ""}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="완료"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="PAID"
                                checked={showingState.status === "PAID"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="대기"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="WAITING_FOR_DEPOSIT"
                                checked={
                                  showingState.status === "WAITING_FOR_DEPOSIT"
                                }
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="취소"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="CANCELED"
                                checked={showingState.status === "CANCELED"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="환불"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-5`}
                                value="REFUND"
                                checked={showingState.status === "REFUND"}
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                              <Form.Check
                                inline
                                label="부분 취소"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-6`}
                                value="PARTIALLY_CANCELED"
                                checked={
                                  showingState.status === "PARTIALLY_CANCELED"
                                }
                                onChange={({ target }) =>
                                  showingDispatch({
                                    type: "changed_status",
                                    payload: target.value,
                                  })
                                }
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                    <Row className="p-1 align-items-center justify-content-center mt-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">발행일</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={showingState.startDate}
                          onChange={(date) =>
                            showingDispatch({
                              type: "changed_date",
                              start: date,
                            })
                          }
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <h5 className="mb-0"> ~ </h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={showingState.endDate}
                          onChange={(date) =>
                            showingDispatch({
                              type: "changed_date",
                              end: date,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="align-items-center mt-4"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">시간대 설정</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <select
                          value={showingState.startTime}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_time",
                              start: target.value,
                            })
                          }
                        >
                          <option value="0">00:00</option>
                          <option value="1">01:00</option>
                          <option value="2">02:00</option>
                          <option value="3">03:00</option>
                          <option value="4">04:00</option>
                          <option value="5">05:00</option>
                          <option value="6">06:00</option>
                          <option value="7">07:00</option>
                          <option value="8">08:00</option>
                          <option value="9">09:00</option>
                          <option value="10">10:00</option>
                          <option value="11">11:00</option>
                          <option value="12">12:00</option>
                          <option value="13">13:00</option>
                          <option value="14">14:00</option>
                          <option value="15">15:00</option>
                          <option value="16">16:00</option>
                          <option value="17">17:00</option>
                          <option value="18">18:00</option>
                          <option value="19">19:00</option>
                          <option value="20">20:00</option>
                          <option value="21">21:00</option>
                          <option value="22">22:00</option>
                          <option value="23">23:00</option>
                        </select>
                        ~
                        <select
                          value={showingState.endTime}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_time",
                              end: target.value,
                            })
                          }
                        >
                          <option value="0">00:00</option>
                          <option value="1">01:00</option>
                          <option value="2">02:00</option>
                          <option value="3">03:00</option>
                          <option value="4">04:00</option>
                          <option value="5">05:00</option>
                          <option value="6">06:00</option>
                          <option value="7">07:00</option>
                          <option value="8">08:00</option>
                          <option value="9">09:00</option>
                          <option value="10">10:00</option>
                          <option value="11">11:00</option>
                          <option value="12">12:00</option>
                          <option value="13">13:00</option>
                          <option value="14">14:00</option>
                          <option value="15">15:00</option>
                          <option value="16">16:00</option>
                          <option value="17">17:00</option>
                          <option value="18">18:00</option>
                          <option value="19">19:00</option>
                          <option value="20">20:00</option>
                          <option value="21">21:00</option>
                          <option value="22">22:00</option>
                          <option value="23">23:00</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>주문 번호</th>
                    <th>구매자</th>
                    <th>전화 번호</th>
                    <th>결제 방식</th>
                    <th>가격</th>
                    <th>상태</th>
                    <th>구매 일자</th>
                    <th>취소 일자</th>
                  </tr>
                </thead>
                <tbody>
                  <PaymentBoard payments={payments} />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={payments.totalPages}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
