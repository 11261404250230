import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import LeftMenuItem from "./LeftMenuItem";
import { Image } from "react-bootstrap";

export default function LeftMenu(props) {
  const pathName = useLocation().pathname;

  function getPathByDepth(path, depth) {
    let pathSplit = path.split("/");
    return (
      "/" +
      pathSplit
        .filter((v, index) => {
          if (index <= depth) return v;
        })
        .join("/")
    );
  }

  const sideBar = [
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_dashboard.png",
      name: "대시보드",
      path: "/admin/home",
      move: false,
      children: [{ name: "대시보드", path: "/admin/home" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_store.png",
      name: "가맹점",
      path: "/admin/store",
      move: false,
      children: [{ name: "가맹점 목록", path: "/admin/store/stores" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
      name: "회원",
      path: "/admin/user",
      move: false,
      children: [
        { name: "회원 목록", path: "/admin/user/users" },
        { name: "비회원 목록", path: "/admin/user/non-users" },
      ],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_gift.png",
      name: "교환권",
      path: "/admin/coupon",
      move: false,
      children: [{ name: "교환권 목록", path: "/admin/coupon/coupons" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_calculator.png",
      name: "결제",
      path: "/admin/payment",
      move: false,
      children: [{ name: "결제 목록", path: "/admin/payment" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_calculator.png",
      name: "정산",
      path: "/admin/sale",
      move: false,
      children: [{ name: "정산 내역", path: "/admin/sale/settlement" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_member.png",
      name: "어드민",
      path: "/admin/admins",
      move: false,
      children: [{ name: "어드민 목록", path: "/admin/admins/users" }],
    },
    {
      imgsrc:
        "https://www.flexdaycdn.net/public/images/static/ui/icon_store.png",
      name: "광고",
      path: "/admin/advertisements",
      move: false,
      children: [{ name: "광고 내역", path: "/admin/advertisements/list" }],
    },
  ];

  return (
    <>
      <div className="LeftMenu">
        <div className="p-4 pt-5">
          <Image
            src="https://www.flexdaycdn.net/public/images/static/ui/flexday_logo_white.png"
            className="img-fluid"
          ></Image>
        </div>
        <Accordion defaultActiveKey={getPathByDepth(pathName, 2)} flush>
          {sideBar.map((value) => (
            <LeftMenuItem
              imgsrc={value.imgsrc}
              name={value.name}
              path={value.path}
              key={value.name}
              move={value.move}
              children={value.children}
            />
          ))}
        </Accordion>
      </div>
    </>
  );
}
