import { apiClient, apiClientTempTemp } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

const downloadExcel = (response) => {
  const blob = new Blob([response.data]);
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = fileUrl;
  link.style.display = "none";
  link.download = getFileName(response);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileName = (response) => {
  const disposition = response.headers["content-disposition"];

  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, "")
  );
  return fileName;
};

export function useExcel(url) {
  const { mutate, isLoading } = useMutation(
    async (params) => {
      return await apiClient({
        url,
        method: "get",
        params: { ...params },
        responseType: "arraybuffer",
      });
    },
    {
      onSuccess: (response) => {
        downloadExcel(response);
      },
      onError: async ({ response }) => {
        const blob = new Blob([response.data]);
        const { message } = JSON.parse(await blob.text());

        alert(message);
      },
    }
  );

  return { mutate, isLoading };
}
