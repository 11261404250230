import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

export function useUserDetail({ id }) {
  const { data: user } = useFetch([], async () => {
    const { data } = await apiClient({
      url: `/users/${id}`,
      method: "get",
    });
    return data;
  });

  return { user };
}
