import { useState, useReducer, useEffect, useCallback } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import DetailPagination from "../../../components/common/DetailPagination";
import { useNonUsers } from "../../../hooks/user/useNonUsers";
import {
  initialState,
  nonUserReducer,
} from "../../../reducers/user/nonUserReducer";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import NonUserBoard from "../../../components/user/NonUserBoard";
import { transformYYYYMMDD } from "../../../function/date";
import { useLocation, useNavigate } from "react-router-dom";
import ExportExcelButton from "../../../components/common/ExportExcelButton";

export default function NonUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [showingState, showingDispatch] = useReducer(
    nonUserReducer,
    initialState
  );
  const [searchState, searchDispatch] = useReducer(
    nonUserReducer,
    initialState
  );

  const { nonUsers, isLoading } = useNonUsers({ page, searchState });

  const setPageStatus = useCallback(() => {
    const [_, page, name, phoneNumber, startDate, endDate] = location.search
      .replace(/\?page=|&name=|&phoneNumber=|&startDate=|&endDate=/g, " ")
      .split(" ");

    if (!page) {
      setPage(0);
      showingDispatch({ type: "reseted" });
      searchDispatch({ type: "reseted" });
      return;
    }

    const getPayload = () => {
      return {
        name: decodeURI(name),
        phoneNumber: decodeURI(phoneNumber),
        startDate: new Date(decodeURI(startDate)),
        endDate: new Date(decodeURI(endDate)),
      };
    };

    setPage(page);
    showingDispatch({
      type: "moved",
      payload: getPayload(),
    });
    searchDispatch({
      type: "moved",
      payload: getPayload(),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  if (!nonUsers) {
    return <CustomSpinner />;
  }

  const getNavigateStr = (state, page = 0) => {
    const { name, phoneNumber, startDate, endDate } = state;

    return `?page=${page}&name=${name}&phoneNumber=${phoneNumber}&startDate=${transformYYYYMMDD(
      startDate
    )}&endDate=${transformYYYYMMDD(endDate)}`;
  };

  const searchNonUserInfo = () => {
    if (isLoading) {
      return;
    }

    searchDispatch({ type: "moved", payload: showingState });
    navigate(getNavigateStr(showingState));
  };

  const handleSearchBtnClick = () => {
    searchNonUserInfo();
  };

  const handleInputKeyDown = ({ key }) => {
    if (key !== "Enter") {
      return;
    }

    searchNonUserInfo();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">비회원 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{nonUsers.totalElements}</h5>
              </Col>
              <Col xs={12} lg={"auto"}>
                <ExportExcelButton
                  btnText="비회원 목록 전체 엑셀 다운로드"
                  exportTargetName="비회원 목록"
                  apiUrl={"/all-non-users"}
                  sheetColumn={[
                    {
                      header: "id",
                      key: "id",
                    },
                    {
                      header: "이름",
                      key: "name",
                    },
                    {
                      header: "전화번호",
                      key: "phoneNumber",
                    },
                    {
                      header: "상태",
                      key: "userStatus",
                    },
                    {
                      header: "로그인 종류",
                      key: "oauthType",
                    },
                    {
                      header: "이메일",
                      key: "email",
                    },
                    {
                      header: "마케팅 동의",
                      key: "marketingConsent",
                    },
                    {
                      header: "기기 정보",
                      key: "deviceInfo",
                    },
                    {
                      header: "생성일자",
                      key: "createdAt",
                    },
                  ]}
                  dataFormatter={(data) => {
                    return data.map((item) => ({
                      ...item,
                      userStatus: item.userStatus?.description,
                      marketingConsent: item.marketingConsent
                        ? "동의"
                        : "동의 안함",
                    }));
                  }}
                />
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.name}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_name",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원 전화번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          value={showingState.phoneNumber}
                          onChange={({ target }) =>
                            showingDispatch({
                              type: "changed_phoneNumber",
                              payload: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={"auto"}>
                    <h5 className="mb-0 fw-bold">발행일</h5>
                  </Col>
                  <Col xs={"auto"}>
                    <DatePicker
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      selected={showingState.startDate}
                      onChange={(date) =>
                        showingDispatch({
                          type: "changed_date",
                          start: date,
                        })
                      }
                    />
                  </Col>
                  <Col xs={"auto"}>
                    <h5 className="mb-0"> ~ </h5>
                  </Col>
                  <Col xs={"auto"}>
                    <DatePicker
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      selected={showingState.endDate}
                      onChange={(date) =>
                        showingDispatch({
                          type: "changed_date",
                          end: date,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center mt-3">
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>이름</th>
                    <th>이메일</th>
                    <th>전화 번호</th>
                    <th>상태</th>
                    <th>로그인 종류</th>
                    <th>생성일자</th>
                  </tr>
                </thead>
                <tbody>
                  <NonUserBoard nonUsers={nonUsers} />
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={nonUsers.totalPages}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
