import React from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../common/useFetch";
import { apiClient } from "../../api/apiClient";

const POST_SIZE = 10;

const useAdvertisements = ({ page, adRequestRef }) => {
  const location = useLocation();

  const { data: advertisements } = useFetch([page], async () => {
    if (adRequestRef.current && page === 0 && !!location.search) {
      return;
    }

    const { data } = await apiClient({
      url: "/advertisements",
      method: "get",
      params: {
        page,
        size: POST_SIZE,
      },
    });

    return data;
  });

  return { advertisements };
};

export default useAdvertisements;
