import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import { useUserDetail } from "../../../hooks/user/useUserDetail";

export default function UserDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserDetail({ id });

  if (!user) {
    // return <CustomSpinner />;
    return;
  }

  const { name, oauthType, marketingConsent, phoneNumber, userStatus, email } =
    user;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">회원 상세정보</h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <div style={{ width: "100%" }}>
                <div
                  className="pb-4 btn-py"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    뒤로가기
                  </button>
                </div>
              </div>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      회원번호 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="매장명을 입력하세요."
                      value={id}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      회원명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="회원명을 입력하세요."
                      value={name || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      이메일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="이메일을 입력하세요."
                      value={email || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      휴대폰번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="휴대폰 번호를 입력하세요."
                      value={phoneNumber || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      가입방식 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가입방식을 입력하세요."
                      value={oauthType}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      마케팅 동의여부 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="마케팅 동의여부를 입력하세요."
                      value={marketingConsent ? "동의" : "비동의"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      회원상태 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="회원상태를 입력하세요."
                      value={userStatus ? userStatus.description : "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
