import HorizonBarChart from "../chart/HorizonBarChart";
import VerticalBarChart from "../chart/VerticalBarChart";
import PieChart from "../chart/PieChart";
import ContentBoard from "./ContentBoard";
import { useToday } from "../../hooks/dashboard/useToday";
import { getChartInfo } from "../common/dashboard";
import { getTodayReferenceDate } from "../common/date";
import CustomSpinner from "../common/CustomSpinner";

export default function Today() {
  const { todayInfo } = useToday({
    referenceDate: getTodayReferenceDate(),
    periodSearchPatternType: "DAILY",
    storeId: 1,
  });

  if (!todayInfo) {
    return <CustomSpinner />;
  }

  const [today, yesterDay, payment] = todayInfo;
  const { summaries, osLabels, mapedPaymentContent, totalPaymentCount } =
    getChartInfo(yesterDay, today, payment);

  return (
    <div>
      <ContentBoard
        summaries={summaries}
        firstTitle="회원 통계 - 사용 운영체제"
        firstChart={<PieChart {...osLabels} label="인원 수" />}
        secondTitle="결제 수단"
        secondChart={
          <HorizonBarChart
            labels={Array.from(
              { length: mapedPaymentContent.length },
              () => ""
            )}
            datas={mapedPaymentContent}
            paymentCount={totalPaymentCount}
            paymentInfo={payment.content}
          />
        }
        thirdTitle="회원 통계 - 성별, 연령대"
        thirdChart={
          <VerticalBarChart
            labels={[
              "0-12",
              "13-18",
              "19-24",
              "25-29",
              "30-34",
              "35-39",
              "40-44",
              "45-49",
              "50-54",
              "55-59",
              "60-",
            ]}
            firstData={[0, 0, 38, 10, 0, 0, 0, 0, 0, 0, 0]}
            firstLabel={"여성"}
            secondData={[0, 0, 10, 19, 0, 0, 0, 0, 0, 0, 0]}
            secondLabel={"남성"}
          />
        }
      />
    </div>
  );
}
