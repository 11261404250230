import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useModifyAdmin({ refetch }) {
  const { mutate: withDrawalMutate, isLoading: isWithDrawalLoading } =
    useMutation(
      async ({ adminId }) => {
        await apiClient({
          url: `/admins/${adminId}`,
          method: "delete",
        });
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: ({ response }) => {
          const { data } = response;
          const { message } = data;

          alert(`${message}`);
        },
      }
    );

  const { mutate: acceptMutate, isLoading: isAcceptLoading } = useMutation(
    async ({ adminId }) => {
      await apiClient({
        url: `/admins/${adminId}`,
        method: "put",
      });
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return {
    withDrawalMutate,
    isWithDrawalLoading,
    acceptMutate,
    isAcceptLoading,
  };
}
