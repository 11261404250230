import { Pie } from "react-chartjs-2";

export default function PieChart({ labels, datas, label }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          font: {
            size: 14,
          },
        },
      },
    },
    layout: {
      padding: {
        right: 150,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label,
        data: datas,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <Pie width={500} height={200} data={data} options={options} />
    </>
  );
}
