import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import CouponDetailBoard from "../../../components/coupon/CouponDetailBoard";
import { useCouponDetail } from "../../../hooks/coupon/useCouponDetail";

export default function CouponDetail() {
  const navigate = useNavigate();
  const { couponId } = useParams();

  const { coupon } = useCouponDetail({ couponId });

  if (!coupon) {
    return <CustomSpinner />;
  }

  const {
    orderNumber,
    buyerName,
    ownerName,
    nonUserName,
    createdAt,
    expireDate,
    facePrice,
    productName,
    storeName,
    status,
  } = coupon;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">교환권 상세정보</h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <div style={{ width: "100%" }}>
                <div
                  className="pb-4 btn-py"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    뒤로가기
                  </button>
                </div>
              </div>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      주문번호 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="주문번호를 입력하세요."
                      defaultValue={orderNumber}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      구매자명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="구매자명을 입력하세요."
                      defaultValue={buyerName || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      소유자명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="소유자명을 입력하세요."
                      defaultValue={ownerName || nonUserName || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      발행일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="발행일을 입력하세요."
                      defaultValue={
                        createdAt
                          ? createdAt.replace("T", " ").substring(0, 16)
                          : "-"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      유효기간 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="유효기간을 입력하세요."
                      value={expireDate}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      액면가 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="액면가를 입력하세요."
                      defaultValue={facePrice}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      상품명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="상품명을 입력하세요."
                      defaultValue={productName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      가맹점명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점명을 입력하세요."
                      defaultValue={storeName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      사용여부 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사용여부를 입력하세요."
                      defaultValue={status ? status.name : "- "}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <CouponDetailBoard />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
