export default function NonUserBoard({ nonUsers }) {
  const mapedNonUsers = nonUsers.content.map((user) => {
    const { id, name, phoneNumber, userStatus, oauthType, email, createdAt } =
      user;

    return (
      <tr key={id} className="board-row-except">
        <td>{name || "-"}</td>
        <td>{phoneNumber || "-"}</td>
        <td>{userStatus ? userStatus.description : "-"}</td>
        <td>{oauthType || "-"}</td>
        <td>{email || "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
      </tr>
    );
  });

  return mapedNonUsers;
}
