import { apiClient } from "../../api/apiClient";
import { useModifyAdmin } from "../../hooks/admin/useModifyAdmin";
import { useMutation } from "../../hooks/common/useMutation";

export default function AdminBoard({ admins, refetch }) {
  const {
    withDrawalMutate,
    isWithDrawalLoading,
    acceptMutate,
    isAcceptLoading,
  } = useModifyAdmin({ refetch });

  const handleAcceptButtonClick = (adminId) => {
    if (isAcceptLoading) {
      return;
    }

    const result = window.confirm("가입 승인하시겠습니까?");

    if (!result) {
      return;
    }

    acceptMutate({ adminId });
  };

  const handleWithDrawalButtonClick = (adminId) => {
    if (isWithDrawalLoading) {
      return;
    }

    const result = window.confirm("탈퇴시키시겠습니까?");

    if (!result) {
      return;
    }

    withDrawalMutate({ adminId });
  };

  const mapedAdmins = admins.content.map((admin) => {
    const { id, adminStatus, name, loginId, createdAt } = admin;

    return (
      <tr key={id} className="board-row-except">
        <td>{id || "-"}</td>
        <td>{name || "-"}</td>
        <td>{loginId || "-"}</td>
        <td>{adminStatus.description || "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleAcceptButtonClick(id);
            }}
            disabled={adminStatus.code === 4 ? false : true}
          >
            가입 승인
          </button>
        </td>
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              handleWithDrawalButtonClick(id);
            }}
            disabled={adminStatus.code === 1 ? false : true}
          >
            탈퇴
          </button>
        </td>
      </tr>
    );
  });

  return mapedAdmins;
}
