import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomSpinner from "../../../components/common/CustomSpinner";
import SimplePagination from "../../../components/common/SimplePagination";
import StoreBoard from "../../../components/store/StoreBoard";
import { useStores } from "../../../hooks/stores/useStores";

export default function Stores({ storeRequestRef }) {
  const [page, setPage] = useState(0);

  const { stores } = useStores({ page, storeRequestRef });

  if (!stores) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#008f9d" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{stores.totalElements}</h5>
              </Col>
            </Row>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>가맹점 번호</th>
                    <th>가맹점 명</th>
                    <th>영업 시간</th>
                    <th>장소</th>
                    <th>로고</th>
                    <th>운영 상태</th>
                    <th>생성 일자</th>
                  </tr>
                </thead>
                <tbody>
                  <StoreBoard stores={stores} />
                </tbody>
              </Table>
              <SimplePagination
                currentPage={page}
                totalPage={stores.totalPages}
                setPage={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
