export const initialState = {
  name: "",
  phoneNumber: "",
  startDate: new Date(),
  endDate: new Date(),
};

export function nonUserReducer(state, action) {
  switch (action.type) {
    case "changed_name":
      return {
        ...state,
        name: action.payload,
      };
    case "changed_phoneNumber":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "changed_date":
      if (action.start) {
        return {
          ...state,
          startDate: action.start,
        };
      }

      return {
        ...state,
        endDate: action.end,
      };
    case "reseted":
      return { ...initialState };
    case "moved":
      return { ...action.payload };
    default:
      return Error(`Unknown action type: ${action.type}`);
  }
}
