import { apiClient } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useUsedCoupon({ refetch }) {
  const { mutate, isLoading } = useMutation(
    async ({ qrcode }) => {
      return await apiClient({
        url: `coupons/${qrcode}/use`,
        method: "post",
      });
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message } = data;

        alert(`${message}`);
      },
    }
  );

  return { mutate, isLoading };
}
