export default function AdvertisementBoard({ advertisements }) {
  const mappedAdvertisements = advertisements.content.map((ad) => {
    const {
      advertisementId,
      title,
      titleBannerUrl,
      ordering,
      startDate,
      endDate,
      createdAt,
      link,
    } = ad;

    return (
      <tr
        key={advertisementId}
        // onClick={() => navigate(`/admin/store/stores/${storeId}`)}
        className="board-row"
      >
        <td>{advertisementId || "-"}</td>
        <td>{title || "-"}</td>
        <td>
          {titleBannerUrl ? (
            <a href={titleBannerUrl} target="_blank" rel="noreferrer">
              이미지 보기
            </a>
          ) : (
            "-"
          )}
        </td>
        <td>{ordering || "-"}</td>
        <td>{startDate || "-"}</td>
        <td>{endDate || "-"}</td>
        <td>{link || "-"}</td>
        <td>
          {createdAt ? createdAt.replace("T", " ").substring(0, 16) : "-"}
        </td>
      </tr>
    );
  });

  return mappedAdvertisements;
}
