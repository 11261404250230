import { Col, Container, Form, Row } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { useNavigate, useParams } from "react-router-dom";
import CustomSpinner from "../../../components/common/CustomSpinner";
import { usePaymentDetail } from "../../../hooks/payment/usePaymentDetail";

export default function PaymentDetail() {
  const navigate = useNavigate();
  const { orderNumber: orderNumberQuery } = useParams();
  const { payment } = usePaymentDetail({ orderNumber: orderNumberQuery });

  if (!payment) {
    return <CustomSpinner />;
  }

  const {
    buyerId,
    buyerName,
    buyerPhoneNumber,
    canceledAt,
    completedAt,
    nonUserOrder,
    orderNumber,
    payMethod,
    paymentPrice,
  } = payment;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">결제 상세정보</h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <div style={{ width: "100%" }}>
                <div
                  className="pb-4 btn-py"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    뒤로가기
                  </button>
                </div>
              </div>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      구매자번호 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="구매자번호를 입력하세요."
                      defaultValue={buyerId || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      구매자명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="구매자명을 입력하세요."
                      defaultValue={buyerName || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      구매자 전화번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="구매자 전화번호를 입력하세요."
                      defaultValue={buyerPhoneNumber || "-"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      취소일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="취소일을 입력하세요."
                      defaultValue={
                        canceledAt
                          ? canceledAt.replace("T", " ").substring(0, 16)
                          : "-"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      결제일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="결제일을 입력하세요."
                      defaultValue={
                        completedAt
                          ? completedAt.replace("T", " ").substring(0, 16)
                          : "-"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      주문번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="주문번호를 입력하세요."
                      defaultValue={orderNumber}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      결제방식 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="결제방식을 입력하세요."
                      defaultValue={payMethod}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      결제가격 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="결제가격을 입력하세요."
                      defaultValue={paymentPrice}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      비회원 주문 여부 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="비회원 주문 여부를 입력하세요."
                      defaultValue={nonUserOrder ? "Y" : "N"}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
