import { Col, Navbar, Container, Button, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function Btn(props) {
  const navigate = useNavigate();

  switch (props.type) {
    case "LONG":
      let btnStyle = "btn btn-primary";
      if (props.btnStyle !== undefined) {
        btnStyle = "btn " + props.btnStyle;
      }

      let path = "/";
      if (props.path !== undefined) {
        path = props.path;
      }

      return (
        <Col xs={{ span: 12, offset: 0 }}>
          <div className="d-grid gap-1">
            <Link to={path} className={btnStyle} size={props.size}>
              {props.btntext}
            </Link>
          </div>
        </Col>
      );
    case "NAVBACK":
      return (
        <Navbar>
          <Container>
            <Navbar.Brand
              style={{ width: "100%", textAlign: "center", fontSize: "15px" }}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  position: "absolute",
                  left: "30px",
                  verticalAlign: "middle",
                  fontSize: "20px",
                }}
              />
              {props.title}
            </Navbar.Brand>
          </Container>
        </Navbar>
      );
    case "USERNAVBACK":
      return (
        <Navbar>
          <Container>
            <Navbar.Brand
              style={{ width: "100%", textAlign: "center", fontSize: "15px" }}
            >
              <FontAwesomeIcon
                icon={faAngleLeft}
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  position: "absolute",
                  left: "30px",
                  verticalAlign: "middle",
                  fontSize: "20px",
                }}
              />
              {props.title}
            </Navbar.Brand>
          </Container>
        </Navbar>
      );
    case "SEARCHBACK":
      return (
        <Navbar className="SearchNavgate">
          <Navbar.Brand
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "15px",
              color: "#000000",
            }}
          >
            <Container>
              <Row>
                <Col xs={2}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{ verticalAlign: "middle", fontSize: "26px" }}
                  />
                </Col>
                <Col xs={10}>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="검색어를 입력하세요."
                      className="me-2 DefaultShadow"
                      id="SEARCH"
                      aria-label="Search"
                    />
                  </Form>
                </Col>
              </Row>
            </Container>
          </Navbar.Brand>
        </Navbar>
      );
    case "LOCATION":
      return (
        <>
          <Col xs={{ span: 12, offset: 0 }}>
            <div className="d-grid gap-2">
              <Link to={props.path} className="btn btn-outline-secondary" id="">
                {props.btntext}
              </Link>
            </div>
          </Col>
        </>
      );
    case "BACK":
      return (
        <>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="outline-secondary"
            id=""
          >
            {props.btntext}
          </Button>
        </>
      );
    case "API":
      let defaultParams = {
        btnStyle: "btn btn-primary",
        onClick: () => {
          alert("api를 호출 해주세요");
        },
      };
      let customParams = Object.assign({}, defaultParams, props);

      return (
        <Col xs={{ span: 12, offset: 0 }}>
          <div className="d-grid gap-1">
            <Button
              onClick={() => customParams.onClick()}
              className={customParams.btnStyle}
              size={props.size}
              disabled={props.disabled ?? false}
            >
              {props.btntext}
            </Button>
          </div>
        </Col>
      );
    case "EVENT":
      let defaultEventParams = {
        btnStyle: "",
        onClick: () => {
          alert("api를 호출 해주세요");
        },
      };
      let customEventParams = Object.assign({}, defaultEventParams, props);

      return (
        <Button
          variant="outline-secondary"
          onClick={() => customEventParams.onClick()}
          className={customEventParams.btnStyle}
          size={props.size}
        >
          {props.btntext}
        </Button>
      );
    default:
      return (
        <>
          <Button variant="outline-secondary" id={props.id}>
            {props.btntext}
          </Button>
        </>
      );
  }
}
