import { apiClient } from "../../api/apiClient";
import useFetch from "../common/useFetch";

export function useStoreDetail({ id }) {
  const { data: store } = useFetch([], async () => {
    const { data } = await apiClient({
      url: `/stores/${id}`,
      method: "get",
    });

    return data;
  });

  return { store };
}
